import React from "react";
import ReactClass from "create-react-class";
import deepAssign from "assign-deep";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import Status from "../status/Component.jsx";
import Config from "../../../config";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "ExternalDispenserWidget",
  getInitialState: function() {
    return {
      data: {
        ice: 1,
        water: 0
      }
    };
  },

  render: function() {
    const {Header, Content} = WidgetPanel,
        {data: {water, ice}} = this.state,
        imagePath = `${Config.baseDir}components/dashboard-widgets/external-dispenser/images`,
        valveMapping = [
          {icon: `${imagePath}/IceDisp_icon.svg`},
          {icon: `${imagePath}/humidity.svg`}
        ];
    return (
      <WidgetPanel {...this.props}>
        <Header>{this.props.title}</Header>
        <Content>
          <Status icon={valveMapping[0].icon} value={ice ? "READY" : "MAKING..."} />
          <Status className="_pull-right" icon={valveMapping[1].icon} value={water + " LTR"} />
        </Content>
      </WidgetPanel>
    );
  },

  onData: function(data, channel) {
    this.setState({
      channel: channel,
      data: data
    });
  }
}));

const Info = {
  id: "appliance-dashboard:extdispensor",
  name: "External Dispensor Widget",
  type: Widget,
  description: "Show external dispensor status",
  icon: "",
  tags: "appliance refrigerator"
};

export default {
  Info,
  Widget
};
