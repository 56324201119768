/* global console setTimeout history */
import React from "react";
import ReactClass from "create-react-class";
import {Dashboard} from "../../components/dashboard";
import statisticsConfig from "./channel-config";
import ApplianceInfo from "../common/ApplianceInfoComponent.jsx";
import Service from "./Service";

import {Service as ApplianceService, ApplianceLeftNav} from "../appliance";
import * as _ from "lodash";
import ResourcesComponent from "../common/Resources.jsx";
const UnitStatistics = ReactClass({
  displayName: "UnitStatistics",
  componentWillMount() {
    const {response, application: {notifications}, message} = this.props;
    if(!response) {
      notifications.error(message);
    }
  },
  back() {
    history.back();
  },
  render: function() {
    const {response, error, message} = this.props;
    let config = null;
    if(response && !_.isEmpty(response)) {
      config = statisticsConfig(response);
    }
    return (  
    <div className="unit-statistics">
      <div className="row content-header">
        <div className="return-section" onClick={this.back}>
          <i className="icon icon-chevron-left"></i>
          <span>Return</span>
        </div>
        <p>Unit Statistics</p>
      </div>
      {config
          ? <Dashboard config={config}/>
          : <div className="message">
              {_.isEmpty(response) && !error
                  ? "No Records Found"
                  : message}
            </div>}
    </div>
    );
  }
});


export default ReactClass({
  displayName: "UnitStatisticsView",
  getInitialState() {
    return {
      responseData: null,
      loading: true,
      fetchingAppliances: false,
      appliances: []
    };
  },
  componentWillMount() {
    const Appliances = ApplianceService.getCurrentApplianceInfos();
    if(Appliances && Appliances.length > 0) {
      this.setState({
        fetchingAppliances: false,
        appliances: Appliances
      });
    } else {
      this.routeToApplianceSearch();
    }
    const {route: {params: {selectedDeviceId}}} = this.props;
    this.setState({
      loading: true
    });
    Service.fetchUnitStatistics()
      .then(response => {
        this.setState({
          responseData: {
            response
          },
          loading: false
        });
      },
      errRes => {
        this.setState({
          responseData: {
            error: errRes,
            message: "There was an error fetching unit statistics",
            response: null
          },
          loading: false
        });
      });
  },
  routeToApplianceSearch() {
    const {application: app} = this.props;
    app.route("/appliance-search");
  },
  selecteAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        selectAppIdx = selectedDeviceId
            ? appliances.map(a => a.deviceId).indexOf(selectedDeviceId)
            : appliances.map(a => a.serial).indexOf(selectedSerial);

    return appliances[selectAppIdx];
  },
  renderApplianceList() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={selectedDeviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selecteAppliance}
      fetchingAppliances={this.state.fetchingAppliances} />;
  },
  render() {
    const {loading} = this.state,
        currentAppliance = this.getCurrentAppliance(),
        {connected} = currentAppliance || false,
        applianceInfo = currentAppliance
        ? ([<ResourcesComponent currentAppliance={currentAppliance}/>,
            <ApplianceInfo application={this.props.application}
                appliance={currentAppliance}></ApplianceInfo>
          ])
        : null;
    return (
      <div className="view unit-statistics-view layout">
        {this.renderApplianceList()}
        <div className="main-content">
          {applianceInfo}
          {loading ?
            <div className="loading spin-loader">
              <div className="_text-center anim">
                <i className="icon icon-loader spin"></i>
              </div>
              <p className="_text-center">Loading Unit Statistics Data...</p>
            </div>
            : <UnitStatistics {...this.props} {...this.state.responseData} />}
        </div>
      </div>
    );
  }
});
