/* global setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";

import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import * as _ from "lodash";
import Config from "../../../config";
import HideByApplianceType from "../../../modules/dashboard/HideByApplianceType.jsx";
import DashboardService from "../../../modules/dashboard/Service";
import {omitKeys} from "../../../modules/appliance/DiagnosticsPanel.jsx";
import AnswerAdviser from "../../../modules/answer-adviser";
import Util from "../../util";
import UserPermissions from "../../../modules/auth/UserPermissions";
import util from "../../util";
const handler = () => {};

const getReadableTime = (date = new Date(), showMs) => {
  const hrs = date.getHours(), mins = date.getMinutes(), secs = date.getSeconds();
  let ampm = hrs < 12 ? " AM" : " PM",
      hr = (hrs > 12) ? hrs - 12 : hrs,
      min = mins < 10 ? "0" + mins : mins,
      sec = secs < 10 ? "0" + secs : secs;

  hr = hr == 0 ? 12 : (hr < 10) ? "0" + hr : hr;
  return [hr, min, sec].join(":") + (showMs ? ": " + date.getMilliseconds() : "") + ampm;
};



const Widget = AsSubscribedComponent(ReactClass({
  renderComponent() {
    const date = new Date(),
        {data: diagnosticsData = {}, updated, updatedOn = date, firmwareUpdateData, isLatestFirmwareVersion} = this.state,
        updatedOnStr = getReadableTime(updatedOn),
        {appliance, latest, answerId, lastRecordedTimeStamp} = diagnosticsData,
        {status, versionAttempted, createdTime, aaLink, createdEpochTime} = firmwareUpdateData,
        recordedOn = lastRecordedTimeStamp ? `${Util.formatDate(new Date(lastRecordedTimeStamp), "YYYY-MM-DD HH:mm:ss a", false)}` : "Not available",
        firmwareStatus = ((appliance.pumaVersion) && (typeof isLatestFirmwareVersion === "boolean" && isLatestFirmwareVersion !== true)) ? "firmware-status" : "",
        imgBasePath = `${Config.baseDir}components/dashboard-widgets/connectivity/images`,
        frmwarUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=Connected Module Update
            &body=Issue: Please update the connected module on this appliance.
            %0D%0AMAC Address: ${appliance.deviceId}
            %0D%0ACustomer Time Zone: `;
    let param = AnswerAdviser.getCRMTicket();
    const {groupDetails} = this.state,
        aaUrl = `${Config.answerAdvisorLink}/multi-brand/connected-module-firmware-updates`;
    const {CRM_TICKET_ID} = param,
        crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "";
    const {applianceDetails} = this.props.context;
    const aaLinkk = (<a target="_blank" href={`${Config.answerAdvisorLink}/multi-brand/service-connected-diagnostics-check-points-good`}
            id="diagnostic details">
            Diagnostic Details
          </a>);

    let statusIcon;
    if (firmwareUpdateData && Object.keys(firmwareUpdateData).length > 0) {
      let statusType = status.split(/\s?\-\s?/)[0],
          statusIconPath, statusIconTooltip;
      if(Util.isPumaDevice(applianceDetails.type) && Util.getPumaFirmwareMajorVersion(applianceDetails.pumaVersion)<8){
        //consider old status map
        switch(statusType) {
          case "COMPLETED":
            statusIconPath = `${imgBasePath}/status-green.svg`;
            statusIconTooltip = "Last update successful";
            break;
          case "ENQUEUD":
          case "WAITING":
          case "SENT":
            statusIconPath = `${imgBasePath}/status-yellow.svg`;
            statusIconTooltip = "Update in progress";
            break;
          default:
            statusIconPath = `${imgBasePath}/status-red.svg`;
            statusIconTooltip = "Last update failed";
            break;
        }
      }
      else{
        //consider new status mapping for all Puma devices having version greater than or equal to 8
        switch(statusType.toUpperCase()) {
          case "CURRENT":
            statusIconPath = `${imgBasePath}/status-green.svg`;
            statusIconTooltip = "Last update successful";
            break;
          case "APPLIANCE_PENDING":
          case "DOWNLOADING":
          case "VERIFYING":
          case "APPLYING":
          case "REBOOTING":
            statusIconPath = `${imgBasePath}/status-yellow.svg`;
            statusIconTooltip = "Update in progress";
            break;
          case "ERROR":
          case "ROLLBACK":
          case "NOT DEFINED":
          default:
            statusIconPath = `${imgBasePath}/status-red.svg`;
            statusIconTooltip = "Last update failed";
            break;
        }        
      }
  
      statusIcon = <img title={statusIconTooltip} src={statusIconPath} id="firmware_updates"/>;
    }
    return (
      <div>
        <div className="timestamps">
          <div>
            <span>Last Recorded</span>
            <span className="last-updated">Last Updated</span>
          </div>
          <div>
            <span className="ts">{recordedOn}</span>
            <span className={"ts _pull-right " + (updated ? "updated" : "")}>
              {/* {`${updatedOnStr}`} */}
              {Util.formatDate(new Date(), "YYYY-MM-DD HH:mm:ss a", false)}
            </span>
          </div>
        </div>
        {
          Object.keys(latest.records).length > 0
            ? <div className="answer-adviser">
                {aaLinkk}
               </div>
            : null
        }
        <div className="appliance-info-panel">
          <div className="appliance-details">
            {
              applianceDetails.type && applianceDetails.type.substring(0,2) === "17"
              ?  <div>
                  <span>Connected Module Version</span>
                  <label>{groupDetails.FirmwareVersion || "N/A"}</label>
                </div>
              : <div>
                <span>Connected Module Version</span>
                <label className={`${appliance.pumaVersion === null ? "firmware-status" : ""} ${firmwareStatus} ${["OFFLINE", "UNAVAILABLE"].indexOf(appliance.connectedStatus) > -1 && !isLatestFirmwareVersion ? " warnning-status " : ""}`}
                  id="module-version">
                    {appliance.pumaVersion === null
                      ? "Version below minimum"
                      : appliance.pumaVersion || "N/A"}
                </label>
                {
                  UserPermissions.allowSendFirmwareRequest()?
                  ["OFFLINE", "UNAVAILABLE"].indexOf(appliance.connectedStatus) > -1 && !isLatestFirmwareVersion
                    ? <a target="_blank"
                        className="disabled"
                        title={`Connected module will update \nonce product connects to a Wi-Fi network.`}>
                        <img src={`${imgBasePath}/warning_icon.svg`} id="firmware_version"/>
                      </a>
                    : appliance.pumaVersion === null
                      ? <a href={frmwarUpdateLink} target="_blank"
                          title={`Connected Module out of date.\nClick to request update.`}>
                          <img src={`${imgBasePath}/frmwr_updt.svg`} id="firmware_version"/>
                        </a>
                      : appliance.pumaVersion &&
                        (typeof isLatestFirmwareVersion === "boolean" && isLatestFirmwareVersion !== true)
                        ? <a href={frmwarUpdateLink} target="_blank"
                             onClick={
                              (e)=>{
                                util.trackEventWithProperties("FIRMWARE_UPDATE", 'Update Firmware',{
                                  ...firmwareUpdateData,
                                  serial:appliance.serial,
                                  model:appliance.model,
                                  deviceId: appliance.deviceId,
                                  name:appliance.name,
                                  productFamily: appliance.productFamily,
                                  type:appliance.type,
                                  controlBoardModel:appliance.controlBoardModel,
                                  versions: JSON.stringify(appliance.versions),
                                });
                              }
                             }
                            title={`Connected Module out of date.\nClick to request update.`}>
                            <img src={`${imgBasePath}/frmwr_updt.svg`} id="firmware_version"/>
                          </a>
                        : null
                    : null
                }
                </div>
  }
            <div>
              <span>MAC </span>
              {/* <label>{appliance.deviceId || "N/A"}</label> */}
              <label>{applianceDetails.deviceId || "N/A"}</label>
            </div>
            <div>
              <span>bda </span>
              {/* <label>{appliance.bda || "N/A"}</label> */}
              <label>{applianceDetails.bda || "N/A"}</label>
            </div>
            {
              applianceDetails.type && applianceDetails.type.substring(0,2) === "17"
              ?
                <div className="group-details">
                  <div>
                    <span>Product Name </span>
                    <label>{groupDetails.productName || "N/A"}</label>
                  </div>
                  <div>
                    <span>Group Name </span>
                    <label>{groupDetails.groupName || "N/A"}</label>
                  </div>
                </div>
              : null
            }
          </div>
        </div>
        { applianceDetails.type && applianceDetails.type.substring(0,2) !== "17" &&
         <div>
            {firmwareUpdateData && Object.keys(firmwareUpdateData).length === 0
              ? this.state.firmwareUpdateFlag === true
                ? <div className="firmware-updates">
                  <div className="heading"><span>Firmware Updates</span></div>
                  <div>
                    <span>Status</span>
                    <label>No history available</label>
                  </div>
                </div>
                : <div className="firmware-updates">
                  <div className="heading">
                    <i className="anim icon icon-loader spin"></i>
                    <span>Firmware Updates</span>
                  </div>
                </div>
              : this.state.firmwareUpdateFlag === true
                ? <div className="firmware-updates">
                  <div className="heading">
                    <span>Firmware Updates {statusIcon}</span>
                  </div>
                  <div>
                    <span>Status</span>
                    <label id="status">{status || "N/A"}</label>
                  </div>
                  <div>
                    <span>Version Attempted</span>
                    <label id="version-attempted">{versionAttempted || "N/A"}</label>
                  </div>
                  <div>
                   
                    {/* <label id="created-time">{`${createdTime} UTC` || "N/A"}</label> */}
                    {
                        Util.isPumaDevice(applianceDetails.type) && Util.getPumaFirmwareMajorVersion(appliance.pumaVersion)<8 ? //Major version for puma is < 8
                          (    
                            <div> 
                              <span>Created Time</span> 
                              <label id="created-time">{createdEpochTime && createdEpochTime != "0" ? Util.formatDate(new Date(parseInt(createdEpochTime)), "yyyy-MM-dd HH:mm:ss", false) : "N/A"}</label>
                            </div>
                          )
                          : null
                    }
                  </div>
                  <div onClick={() => Util.trackAAEvent(aaUrl)}>
                    <a href={`${Config.answerAdvisorLink}/multi-brand/connected-module-firmware-updates`} target="_blank" id="firmware-update-aa">Changes included with this version.</a>
                  </div>
                </div>
                : <div className="firmware-updates">
                  <div className="heading">
                    <i className="anim icon icon-loader spin"></i>
                    <span>Firmware Updates</span>
                  </div>
                </div>
            }
          </div>
      }
      </div>
    );
  },

  getInitialState() {
    return {
      data: {
        appliance: {
          pumaVersion: "",
          deviceId: "",
          bda: ""
        },
        latest: {
          records: {}
        },
        records:{
        }
      },
      flag: false,
      firmwareUpdateFlag: false,
      loading: true,
      firmwareUpdateData: {},
      isLatestFirmwareVersion: "",
      groupDetails: {}
    };
  },
  componentDidUpdate() {
    this.props.layout.layout();
  },
  componentDidMount() {
    const {applianceDetails} = this.props.context;
    if(applianceDetails.type && applianceDetails.type.substring(0,2) === "17") {
      DashboardService.fetchGroupDetails(applianceDetails).then(res => {
        this.setState({
          groupDetails: res
        });
        DashboardService.setCurrentApplianceDetails(res);
      }).catch(errRes => {
        this.setState({
          groupDetails: {}
        })
      })
    }else {
      DashboardService.fetchFirmwareUpdates(applianceDetails).then(res => {
        this.setState({
          firmwareUpdateData: res,
          firmwareUpdateFlag: true
        });
      }).catch(errRes => {
        this.setState({
          firmwareUpdateData: {},
          firmwareUpdateFlag: true
        });
      });
    }
  },
  onSettings(action) {
    const {onAction = handler} = this.props,
        {data} = this.state;
    if(action === "refresh") {
      this.setState({
        loading: true
      });
    }
    if(action === "settings") {
      Util.trackPageWidgetEvent("WIDGET_CLICK", "Diagnostics", data.appliance.deviceId);
    }
    onAction(action, this.state.data);
  },

  checkDiagStatus(status) {
    const {data: {records}} = this.state,
        recordsArray = Object.keys(records).filter(each => omitKeys.indexOf(each) === -1);
    return recordsArray.length && recordsArray.some(record => records[record] === status);
  },

  getDiagStatus() {
    if(this.checkDiagStatus("BAD"))
      return {className: "icon-alert-triangle status", title: "STATUS : BAD"};
    else if(this.checkDiagStatus("UNKNOWN"))
      return{className: "icon-question_icon status", title: "STATUS : UNKNOWN"};
    else if(this.checkDiagStatus("GOOD")) {
      const {data: {appliance: {azureTwinConnectedStatus: azureStatus}}} = this.state;
      if(azureStatus === "Connected")
        return {className: "icon-check-circle status", title: "STATUS : GOOD-CONNECTED"};
      else
        return {className: "icon-alert-triangle status",
          title: `STATUS : GOOD-${azureStatus.toUpperCase()}`};
    } else
      return {};
  },

  render() {
    const {Header, Content} = WidgetPanel,
        panelContent = this.renderComponent(),
        status = this.getDiagStatus(),
        {latest = {}, history = {}, appliance} = this.state.data,
        {records = {}} = latest;

    let title = (_.isEmpty(appliance) || _.isEmpty(latest)) ? "Data is not yet available" : (_.isEmpty(history) ? "History is not yet available" : null);

        if(Object.keys(status).length > 0)
          title = "Diagnostic bit details";

    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span id="connected-module">{this.props.title}</span>
          <HideByApplianceType name="connectivity::refresh">
            <a title="Refresh" className={`_pull-right  clickable-icon${this.state.loading ? " disabled" : ""}`}><i className={`icon icon-rotate-cw${this.state.loading ? " anim spin" : ""}`} onClick={this.onSettings.bind(this, "refresh")}/></a>
          </HideByApplianceType>
          <HideByApplianceType name="connectivity::settings">
            <a title={title} className={`_pull-right clickable-icon ${(_.isEmpty(latest) || _.isEmpty(history) || _.isEmpty(records)) ? "disabled" : "activable"}`}><i className="icon icon-activity" onClick={this.onSettings.bind(this, "settings")}></i></a>
          </HideByApplianceType>
          { Object.keys(status).length > 0
            ? <i className={`_pull-right icon ${status.className}`} title={status.title} />
            : null }
        </Header>
        <Content>
          {panelContent}
        </Content>
      </WidgetPanel>
    );
  },
  onData(data, channel) {
    if(channel === "/appliances/deviceStatus") {
      const {deviceStatus: {isLatestFirmwareVersion}} = data;
      this.setState({
        channel: channel,
        isLatestFirmwareVersion
      })
    }else if(channel === "/appliances/diagnostics/connectivity") {
    const {latest: {records}} = data,
        len = Object.keys(records).length;
    this.setState({
      channel: channel,
      data: data,
      updated: true,
      updatedOn: new Date(),
      flag: len === 0 ? true : false,
      loading: false
    });
    setTimeout(() => {
      this.setState({
        updated: false
      });
    }, 2000);
  }
}
}));

const Info = {
  id: "appliance-dashboard:connectivity",
  name: "Appliance Connectivity",
  type: Widget,
  description: "Shows current appliance connectivity",
  icon: "",
  tags: "appliance connectivity"
};

export default {
  Info,
  Widget
};
