/* global history*/
import ReactClass from "create-react-class";
import React from "react";
import {ApplianceLeftNav, Service as ApplianceService} from "../appliance";
import ApplianceInfo from "../common/ApplianceInfoComponent.jsx";
import {LogService} from "./";
import ViewLogsComponent from "./ViewLogsComponent.jsx";
import * as _ from "lodash";
import ResourcesComponent from "../common/Resources.jsx";

export default ReactClass({
  displayName: "ViewData",
  getInitialState() {
    return {
      // Appliance List needed following 3 objects
      fetchingAppliances: false,
      appliances: [],
      logs: [],
      busy: false,
      errorMessage: null,
      temperature: null,
      data: null
    };
  },
  componentWillMount() {
    const Appliances = ApplianceService.getCurrentApplianceInfos();
    if(Appliances && Appliances.length > 0) {
      this.setState({
        fetchingAppliances: false,
        appliances: Appliances
      });
      this.fetchLogs(Appliances);
    } else {
      this.routeToApplianceSearch();
    }
  },

  fetchLogs(appliances) {
    const {application, route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        notifications = application.notifications,
        selectAppIdx = selectedDeviceId
            ? appliances.map(a => a.deviceId).indexOf(selectedDeviceId)
            : appliances.map(a => a.serial).indexOf(selectedSerial),
        currentAppliance = appliances[selectAppIdx],
        serial = currentAppliance && currentAppliance.serial;
    this.setState({
      busy: true
    });
    
    LogService.fetchLogs(selectedDeviceId, serial).then(resData => {
      const {logs, Temperature: temperature, Data: data} = resData;
      this.setState({
        logs: logs,
        data,
        temperature,
        busy: false
      });
      const errorLogs = _.filter(logs, ele => !!ele.error);
      if(errorLogs && errorLogs.length) {
        notifications.error(`Failed to fetch ${errorLogs.length} log(s).`);
      }
    }).catch(errRes => {
      errRes.json().then(err => {
        const errorMessage = "Error fetching appliance information.";
        this.setState({
          busy: false,
          errorMessage
        });
        notifications.error(errorMessage);
      });
      throw errRes;
    });
  },

  // Appliance List - need following 5 functions
  routeToApplianceSearch() {
    const {application: app} = this.props;
    app.route("/appliance-search");
  },
  selecteAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  renderApplianceList() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={selectedDeviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selecteAppliance}
      fetchingAppliances={this.state.fetchingAppliances} />;
  },

  back() {
    history.back();
  },
  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        selectAppIdx = selectedDeviceId
            ? appliances.map(a => a.deviceId).indexOf(selectedDeviceId)
            : appliances.map(a => a.serial).indexOf(selectedSerial);

    return appliances[selectAppIdx];
  },
  routeTo(log) {
    const {logType, data, applianceType} = log,
        type = logType.toLowerCase(),
        {application: app} = this.props,
        currentAppliance = this.getCurrentAppliance(),
        {deviceId, serial} = currentAppliance;
    if(type === "data") {
      return;
    } else if(type === "temperature") {
      const path = deviceId
          ? `/appliance/${deviceId}/non-connected/${applianceType}/graph/${logType}`
          : `/appliance/serial/${serial}/non-connected/${applianceType}/graph/${logType}`;
      LogService.setLogData(this.state[type]);
      app.route(path);
    } else if(type === "unit statistics") {
      const path = deviceId
          ? `/appliance/${deviceId}/unit-statistics`
          : `/appliance/unit-statistics/${serial}`;
      LogService.setLogData(data);
      app.route(path);
    } else if(type === "fault") {
      const path = deviceId
          ? `/appliance/${deviceId}/faults`
          : `/appliance/faults/${serial}`;
      LogService.setLogData(data);
      app.route(path);
    }
  },
  render() {
    const {logs, busy, errorMessage} = this.state,
        {application} = this.props,
        currentAppliance = this.getCurrentAppliance(),
        {connected} = currentAppliance || false,
        applianceInfo = currentAppliance
            ? ([<ResourcesComponent currentAppliance={currentAppliance}/>,
                <ApplianceInfo appliance={currentAppliance} application={application}></ApplianceInfo>
              ])
            : null;

    return (
      <div className="view log-view layout">
        {this.renderApplianceList()}
        <div className="main-content">
          {applianceInfo}
          <div className="view-table">
            <div className="row content-header">
              <div className="return-section" onClick={this.back}>
                <i className="icon icon-chevron-left"></i>
                <span>Return</span>
              </div>
              <p>VIEW LOGS</p>
            </div>
            {(connected
              ? <div className="row">
                  <h1> Show Connected Data </h1>
                </div>
              : <ViewLogsComponent logs={logs} busy={busy} errorMessage={errorMessage} routeTo={this.routeTo}/> )}
          </div>
        </div>
      </div>
    );
  }
});
