/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";
import { WidgetPanel, AsSubscribedComponent } from "ui-dashboard";
import HideByApplianceType from "../../../modules/dashboard/HideByApplianceType.jsx";
import Util from "../../util";
import util from "../../util";
import Pagination from "../../widgets/Pagination.jsx";
import ApplianceService from "../../../modules/appliance/Service";

const mapping = {
  "-1": {
    "tooltip": "Unknown - Unsupported",
    "className": "RED"
  },
  "0": {
    "tooltip": "Open - Unsupported",
    "className": "RED"
  },
  "1": {
    "tooltip": "WEP - Unsupported",
    "className": "RED"
  },
  "2": {
    "tooltip": "WPA - PSK",
    "className": "BLUE"
  },
  "3": {
    "tooltip": "WPA2 - PSK",
    "className": "BLUE"
  },
  "4": {
    "tooltip": "WPA - TKIP CCMP",
    "className": "BLUE"
  },
  "5": {
    "tooltip": "WPA - CCMP",
    "className": "BLUE"
  },
  "6": {
    "tooltip": "WPA - TKIP",
    "className": "BLUE"
  },
  "7": {
    "tooltip": "WPA2 - TKIP CCMP",
    "className": "Blue"
  },
  "8": {
    "tooltip": "WPA2 - CCMP",
    "className": "BLUE"
  },
  "9": {
    "tooltip": "WPA2 - TKIP",
    "className": "BLUE"
  }
};

const WifiConnected = ReactClass({
  displayName: "WifiConnected",
  render() {
    let item = [];
    const {totalBar, fill, rssi} = this.props,
        rssiClass = rssi < 15
          ? "red"
          :((rssi >= 15 && rssi <= 24) ? "yellow" : "green");
    for(let i=0; i< totalBar; i++) {
      if(i < fill)
        item.push(<div key={"Wifi_"+i} className={"wifi " + rssiClass}></div>);
      else
        item.push(<div key={"Wifi_"+i} className="wifi"></div>);
    }
    return (
      <div className="status" title={rssi}>
        {item}
      </div>
    );
  }
});

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "ProvisioningReportWidget",
  PRIVIOSNING_STEPS: [
    {//Step 1
      id:1,
      key:"bluetooth_connected",
      displayName:"Bluetooth connected",
      jsonPath:"bluetooth_connected",
      mainObject:"bluetooth_connected",
      status: null
    },
    {//Step 2
      id:2,
      key:"bluetooth_scan_complete",
      displayName:"Bluetooth scan complete",
      jsonPath:"bluetooth_scan.module_selected",
      mainObject:"bluetooth_scan",
      status: null
    },
    {//Step 3
      id:3,
      key:"bluetooth_paired",
      displayName:"Bluetooth paired",
      mainObject:"bluetooth_pairing_complete",
      jsonPath:"bluetooth_pairing_complete",
      status: null
    },
    {//Step 4
      id:4,
      key:"pin_displayed",
      displayName:"PIN displayed",
      mainObject:"pin_pairing_preparation_conditions.condition_pin_displayed",
      jsonPath:"pin_pairing_preparation_conditions.condition_pin_displayed",
      status: null
    },
    {//Step 5
      id:5,
      key:"pin_pairing_complete",
      displayName:"PIN pairing complete",
      mainObject:"pin_pairing_complete",
      jsonPath:"pin_pairing_complete",
      status: null
    },
    {//Step 6
      id:6,
      key:"wifi_connected",
      displayName:"WiFi connected",
      mainObject:"wifi_setup.selected_network",
      jsonPath:"wifi_setup.selected_network",// module_connected
      status: null
    },
    {//Step 7
      id:7,
      key:"saber_firmware_updates_deferred",
      displayName:"Firmware Updated",
      mainObject:"saber_firmware_updates_deferred",
      jsonPath:"saber_firmware_updates_deferred",
      status: null
    },
    {//Step 8
      id:8,
      key:"association_complete",
      displayName:"Association complete",
      mainObject:"account_association_complete",
      jsonPath:"account_association_complete",
      status: null
    },
    {//Step 9
      id:9,
      key:"time_zone_set",
      displayName:"Time zone set",
      mainObject:"time_zone_setting.selected_time_zone.id",
      jsonPath:"time_zone_setting.selected_time_zone.id",
      status: null
    },
  ],
  getInitialState: function () {
    return {
      data: [],
      loading: true,
      currentPage:0,
      showWifiScanList:false,
      showBluetoothScanList: false,
    };
  },

  componentDidUpdate() {
    this.props.layout.layout();
  },

  getProvisioningStepStatus(stepData){
    return stepData?"status-indicator-success": "status-indicator-failure";
  },

  checkProviosioningStepError(stepKey){
    const fatalErrors =  this.state.data[this.state.currentPage].fatal_errors;
    if(!fatalErrors){
      return null;
    }
    const lastError = fatalErrors;
    if(lastError.step === stepKey){
      return true;
    }
    return null;
  },

  renderWifiScanList(accessPoints, selectedNetwork){
    const appliances = ApplianceService.getCurrentApplianceInfos();
    const appliance = appliances[0];
    let rows;
    if(accessPoints && Array.isArray(accessPoints)){
      const totalBar = 8,
      maxrssi = 70;
      rows = accessPoints.map(item => {
        const clazz = (item.ap_enc >= 1
          ? "icon-lockClose_icon"
          : ( item.encryption === -1
             ? "icon-lockIssue_icon" : "icon-lockOpen_icon")),
            totalFill = item.ap_rssi
              ? appliance.type && appliance.type.substring(0,2) === "1."
                  ? Math.ceil(item.ap_rssi / (maxrssi / totalBar))
                  : Math.ceil((95 - Math.abs(item.ap_rssi)) / (maxrssi / totalBar))
              : 0,
            mappingItem = mapping[item.ap_enc+""],
            toolTip = mappingItem.tooltip,
            color = mappingItem.className;
        return (
          <li key={item.ap_ssid}>
            <div className="icon-cont">
              <i className={
                `icon icon-check-circle ${item.ap_ssid === selectedNetwork ? "" : "faded"}`
              } />
            </div>
            <div title={item.ap_ssid}
                 className={`wifi-ssid text ${item.ap_ssid === selectedNetwork ? "selected" : ""}`}>
              {item.ap_ssid}
            </div>
            <div className="icon-cont">
              {(clazz === "icon-lockIssue_icon"
                ? <i className={`icon ${clazz} ${color}`} title={toolTip} >
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                : <i className={`icon ${clazz} ${color}`} title={toolTip} />)}
            </div>
            <div className="wifi-widget">
              <WifiConnected totalBar={totalBar} fill={totalFill} 
                  rssi={appliance.type && appliance.type.substring(0,2) === "1." ? item.ap_rssi : (95 - Math.abs(item.ap_rssi))}/>
            </div>
          </li>
        );
      });
    }
    return(
      <div className="scan-list-container">
        <div className="consumer-wifi-scan-list">
          {
            !this.state.showWifiScanList?
            <a className="details-text" onClick={()=>{
              this.setState({
                ...this.state,
                showWifiScanList:true
              })
            }}>More details</a>:null
          }
    
          {
            this.state.showWifiScanList?
              (!accessPoints || accessPoints=="null" || !Array.isArray(accessPoints)?(<div className="empty">No networks detected</div>)
              :
                <ul>{rows}</ul>)    
              :null
          }
        </div>
        {
          this.state.showWifiScanList?
          <a className="details-text" onClick={()=>{
                  this.setState({
                    ...this.state,
                    showWifiScanList:false
                  })
              }}>Less details</a>: null
        }
      </div>

    )
  },

  renderBluetoothScanList(detectedModules, selectedModuleBda){
    const appliances = ApplianceService.getCurrentApplianceInfos();
    const appliance = appliances[0];
    let rows;
    if(detectedModules){
      const totalBar = 8,
      maxrssi = 100;
      rows = detectedModules.map(item => {
        const 
            totalFill = Math.abs(item.rssi)
              ? appliance.type && appliance.type.substring(0,2) === "1."
                  ? Math.ceil(Math.abs(item.rssi) / (maxrssi / totalBar))
                  : Math.ceil((95 - Math.abs(item.rssi)) / (maxrssi / totalBar))
              : 0;
        return (
          <li key={item.bda}>
            <div className="icon-cont">
              <i className={
                `icon icon-check-circle ${item.bda !== selectedModuleBda ? "faded" : ""}`
              } />
            </div>
            <div title={item.bda}
                 className={`wifi-ssid text ${item.bda !== selectedModuleBda  === "selected" ? "selected" : ""}`}>
              {item.appliance_type}
            </div>
            <div className="wifi-widget">
              <WifiConnected totalBar={totalBar} fill={totalFill} 
                  rssi={appliance.type && appliance.type.substring(0,2) === "1." ? Math.abs(item.rssi) : (95 - Math.abs(item.rssi))}/>
            </div>
          </li>
        );
      });
    }
    return(
      <div className="scan-list-container">
        <div className="consumer-wifi-scan-list">
          {
            !this.state.showBluetoothScanList?
            <a className="details-text" onClick={()=>{
              this.setState({
                ...this.state,
                showBluetoothScanList:true
              })
            }}>More details</a>:null
          }
    
          {
            this.state.showBluetoothScanList?
              (!detectedModules?(<div className="empty">No bluetooth devices detected </div>)
              :
                <ul>{rows}</ul>)    
              :null
          }
        </div>
        {
          this.state.showBluetoothScanList?
          <a className="details-text" onClick={()=>{
                  this.setState({
                    ...this.state,
                    showBluetoothScanList:false
                  })
              }}>Less details</a>: null
        }
      </div>
    )
  },

  getStatusUiElement(item, provisioningSteps){
    if(item.isFirstFailedStep){
      return (<div className={this.getProvisioningStepStatus(stepStatus || item.overrideWithValue)}></div>);
    }
    console.log('step - ', item, ' ==  ', stepStatus);
    // if(item.status == 'error'){
    //   return (<></>);
    // }
    if(item.status == "error"){
      return (<></>);
    }
    const stepStatus = util.getValueAtPathInObject(item.jsonPath,provisioningSteps);
    if(item.key=='saber_firmware_updates_deferred'){
      return (<div className={this.getProvisioningStepStatus(stepStatus)}></div>);
    }
    if(item.key=='bluetooth_connected'){
      if(provisioningSteps.hasOwnProperty('bluetoothConnected')) {
        return (<div className={this.getProvisioningStepStatus(provisioningSteps.bluetoothConnected)}></div>);
      } else if(provisioningSteps.hasOwnProperty('bluetooth_connected')) {
        return (<div className={this.getProvisioningStepStatus(provisioningSteps.bluetooth_connected)}></div>);
      }
    }
    return (this.getProvisioningStepStatus(stepStatus || item.overrideWithValue)  ? 
           (<div className={this.getProvisioningStepStatus(stepStatus || item.overrideWithValue)}></div>): null);
    
  },

  shouldHideStep(item, provisioningSteps){
    if(item.key==='saber_firmware_updates_deferred'){
      return !this.isSaberDevice();//is puma device, hide this step
    }
    if(item.key=='wifi_connected' && !provisioningSteps.wifi_setup.selected_network
     && !provisioningSteps.wifi_setup.wifi_networks_detected){
      return true;
    }
    return false;//don't hide any other step
  },

  isSaberDevice(){
    const appliances = ApplianceService.getCurrentApplianceInfos();
    if(appliances.length>0){
      const appliance = appliances[0];
      return appliance.type.startsWith("17.");
    }
    return false;
  },

  renderProvisoningSteps(provisioningSteps){
    if(!provisioningSteps){
      return(<div className="empty">Data for priovisioning steps not available.</div>);
    }
    return (
      <>
        {
          this.PRIVIOSNING_STEPS.map((item)=>{
            if(this.shouldHideStep(item, provisioningSteps)) {
              return (<></>)
            }
            return (
            <div>
                <span>{item.displayName}</span>
                <div className="right-container">
                {this.getStatusUiElement(item, provisioningSteps)}
                {
                  item.key=='wifi_connected'?(<div className="step-content">{this.renderWifiScanList(
                    provisioningSteps.wifi_setup.wifi_networks_detected, 
                    provisioningSteps.wifi_setup.selected_network)}</div>):null
                }
                {
                  item.key=='bluetooth_scan_complete'?(<div className="step-content">{this.renderBluetoothScanList(
                    provisioningSteps.bluetooth_scan.modules_detected, 
                    provisioningSteps.bluetooth_scan.module_selected)}</div>):null
                }
                </div>
              </div>
            )
          })
        }
      </>
    )
  },

  renderErrorContent(lastError){
    if(!lastError){
      return  <div className="empty">No error occured.</div>;
    }
    let helpLink; 
    if(lastError.help_link){
      const replaceBy = util.isLoggedInUsingSC()? 'https://www.subzero-wolf.com/assistance/answers/partners':'https://www.subzero-wolf.com/assistance/answers/corp';
      helpLink = lastError.help_link.replace('https://www.subzero-wolf.com/assistance/answers',replaceBy);
    }
    return(
      <>
        <div>
          <span>Title</span>
          <label>{lastError.title}</label>
        </div>
        <div>
          <span>Description</span>
          <label>{lastError.description}</label>
        </div>
        {/* <div>
          <span>Troubleshooting</span>
          <label>
            {
                lastError.troubleshooting_steps && Array.isArray(lastError.troubleshooting_steps)
                && lastError.troubleshooting_steps.length>0 
                && lastError.troubleshooting_steps.map((item)=>{
                  return (<>{'-'+item}<br/></>);
                })
            }{
              !lastError.troubleshooting_steps || 
                lastError.troubleshooting_steps.length<=0
                ? <div className="empty">No troubleshooting steps available</div>
                : null
            }  
          </label>
        </div> */}
        <div>
          <span>Help Link</span>
          <label><a href={helpLink} target="_blank">{helpLink}</a></label>
        </div>
      </>
    )
  },

  renderMobileDeviceInfoContent(deviceInfo){
    return(
      <>
        <div>
            <span>Operating System</span>
            <label>{deviceInfo.operating_system}</label>
        </div>
        <div>
            <span>Phone Model</span>
            <label>{deviceInfo.app_platform}</label>
        </div>
        <div>
            <span>App Version</span>
            <label>{deviceInfo.app_version}</label>
        </div>
      </>
    )
  },

  renderProvisioningReport(data) {
    console.log("renderProvisioningReport--data", this.sate)
    if(!data){
      return null;
    }
    let lastError =  data.fatal_errors;
    return (
      <div>
        <div className="mobile-device-info">
          <div className="heading">
            <span>Mobile Device Info </span>
          </div>
          {this.renderMobileDeviceInfoContent(data.system_info)}
          <div>
            <span>TimeStamp</span>
            <label>{Util.formatDate(new Date(data._ts * 1000), "YYYY-MM-DD HH:mm:ss a", false)}</label>
          </div>
          <div>
            <span>Provisioning Mode</span>
            {(data.provisioning_steps).hasOwnProperty("bluetooth_only_mode") ?
            <label>{data.provisioning_steps.bluetooth_only_mode ? "Bluetooth Only Mode" :  "Connected Mode"}</label> : <label></label>
            }
          </div>
        </div>
        <div className="error-info">
            <div className="heading">
              <span>Error</span>
            </div>
            {this.renderErrorContent(lastError)}
        </div>
        <div className="provisioning-steps">
            {this.renderProvisoningSteps(data.provisioning_steps)}
        </div>
      </div>
    )
    // return data.length > 0
    //   ? data.map((mobNotification, idx) => {
    //     return (
    //       <div key={`notification-section${idx}`}>
    //         {
    //           mobNotification.name !== null || mobNotification.time !== null
    //             ? <div className="notification-section" id={`notification-section${idx}`}>
    //               <div className="name">{mobNotification.name || 'N/A'}</div>
    //               <div className="time">{mobNotification.epochTime != "0" ? Util.formatDate(new Date(parseInt(mobNotification.epochTime)), "yyyy-MM-dd HH:mm:ss", false) : 'N/A'}</div>
    //             </div>
    //             : null
    //         }
    //       </div>
    //     )
    //   })
    //   : (<div className="empty">No mobile notifications available for {tab}.</div>);
  },

  onSettings(action) {
    const {onAction = handler} = this.props,
        {data} = this.state;
    if(action === "refresh") {
      this.setState({
        loading: true,
        currentPage:0
      });
    }
    onAction(action, this.state.data);
  },

  onData: function(data, channel) {
    console.log("provisioning",data,channel);
    if(channel === "/appliance/provisioning-report") {
      try{
        if(data.length>0){
          this.processProviosioningReport(0, data);
        }
        this.setState({
          channel: channel,
          data:data,
          loading:false
        })
      }catch(e){
        console.error(e);
        this.setState({
          channel: channel,
          data:[],
          loading:false
        })
      }
    }
  },

  processProviosioningReport(currentPage, data){
    let lastItem;
    let isStepFailed;
    console.log('processProviosioningReport- Processed PRIVIOSNING_STEPS Before - ', this.PRIVIOSNING_STEPS)
    this.PRIVIOSNING_STEPS.forEach((item)=>{
      if(item.key=='bluetooth_connected'){
        if(data[currentPage].provisioning_steps.hasOwnProperty('bluetoothConnected')) {
          isStepFailed = util.getValueAtPathInObject('bluetoothConnected',data[currentPage].provisioning_steps) == null;
        } else if(data[currentPage].provisioning_steps.hasOwnProperty('bluetooth_connected')) {
          isStepFailed = util.getValueAtPathInObject('bluetooth_connected',data[currentPage].provisioning_steps) == null;
        }
      } else {
        isStepFailed = util.getValueAtPathInObject(item.mainObject,data[currentPage].provisioning_steps) == null;
      }
      item.status = isStepFailed?'error':'success';
      item.isFirstFailedStep = lastItem==null && isStepFailed;
      lastItem = item;
    })
     //override pin dispayed and bluetooth status if pin pairing complete
    const pinPairingStatus = this.PRIVIOSNING_STEPS.find((item)=> item.key === 'pin_pairing_complete');
    const pinDisplayed = this.PRIVIOSNING_STEPS.find((item)=> item.key === 'pin_displayed');
    const bluetoothPaired = this.PRIVIOSNING_STEPS.find((item)=> item.key === 'bluetooth_paired');
    console.log('pinPairingStatus- pinPairingStatus - ', pinPairingStatus, pinDisplayed, bluetoothPaired);
    const pinPairingStatusValue = util.getValueAtPathInObject(pinPairingStatus.jsonPath, data[currentPage].provisioning_steps);
    pinDisplayed.overrideWithValue = pinPairingStatusValue;//value;
    bluetoothPaired.overrideWithValue = pinPairingStatusValue;// value;
    console.log('processProviosioningReport- Processed PRIVIOSNING_STEPS After - ', this.PRIVIOSNING_STEPS)
  },

  onChangePage(index){
    this.processProviosioningReport(index, this.state.data);
    this.setState({
      ...this.state,
      currentPage:index
    })
  },

  renderContent(){
    try{
      return (
        <>
           {
              this.state.data && this.state.data.length>0? 
              this.renderProvisioningReport(this.state.data[this.state.currentPage])
              :         
              <div className="empty">No provisioning reports available</div>
            }
            {
              this.state.data && this.state.data.length>0? 
              <Pagination  onChangePage={this.onChangePage} 
              limit={this.state.data.length} selectedIndex={this.state.currentPage}>
              </Pagination>:null
            }
        </>
      );
    }catch(e){
      console.log("Invalid Provisional Report:", e);
    }
    return (
      <>
        <div className="empty">Unexpected values from provisioning report</div>
      </>
    );
  },
  
  render: function () {
    const { Header, Content } = WidgetPanel,
      { data } = this.state;
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
          <HideByApplianceType name="provisioningReport::refresh">
            <a title="Refresh" className={`_pull-right  clickable-icon${this.state.loading ? " disabled" : ""}`}><i className={`icon icon-rotate-cw${this.state.loading ? " anim spin" : ""}`} onClick={this.onSettings.bind(this, "refresh")}/></a>
          </HideByApplianceType>
        </Header>
        <Content>
          {
            this.renderContent()
          }
        </Content>
      </WidgetPanel>
    );
  }
}));

const Info = {
  id: "appliance-dashboard:provisioningReport",
  name: "Provisioning Report",
  type: Widget,
  description: "Show Provisioning Report",
  icon: "",
  tags: "provisioning report"
};

export default {
  Info,
  Widget
};
