/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import {
  Form,
  rb,
  MultiValueInput,
  Tabs
} from "../../components/widgets";

import {Service as ApplianceService} from "../appliance";
import * as _ from "lodash";
import Config from "../../config";
import DashboardService from "./Service";
import catService from "../cat-projects/Service";
import AnswerAdviser from "../../modules/answer-adviser";
import AI from "../../appInsights";
import Util from "../../components/util"
import UserPermissions from "../auth/UserPermissions";

const rules = {
  phoneNo: [rb("pattern", {
    "pattern": /^[0-9]{10,11}$/,
    "message": "Enter only digits having length 10 or 11"
  })]
};

let applianceInfoPromise;
const imgBasePath = `${Config.baseDir}images`;

export default ReactClass({
  displayName: "ApplianceSearch",
  getInitialState() {
    return {
      selectedTab: 1,
      inputTypeDeviceInfo: "serialNo",
      deviceId: [],
      serialNo: [],
      bda: [],
      inputTypeCustInfo: "emailId",
      userId: "",
      emailId: "",
      phoneNo: "",
      busy: false,
      phoneFrm: null,
      serviceStatus: {}
    };
  },
  componentDidMount() {
    DashboardService.setCurrentApplianceInfo(null);
    catService.setCatProjectName(null);

    DashboardService.fetchServiceStatus().then(res => {
      this.setState({
        serviceStatus: res
      })
    }).catch(errRes => {
      this.setState({
        serviceStatus: {}
      })
    });
  },
  selectedTabType(index) {
    this.setState({
      selectedTab: index
    });
  },

  selectInputTypeDeviceInfo(index) {
    this.setState({
      inputTypeDeviceInfo: index
    });
  },

  selectInputTypeCustInfo(index) {
    this.setState({
      inputTypeCustInfo: index
    });
  },

  handleEnterPress(e) {
    const {charCode} = e;
    if(charCode === 13) {
      this.go();
    }
  },

  storeDeviceId(values) {
    this.abortReq();
    this.setState({
      deviceId: values
    });
  },

  storeSerialNo(values) {
    this.abortReq();
    this.setState({
      serialNo: values
    });
  },

  storeUserId(e) {
    this.abortReq();
    const input = e.target, val = input.value.trim();
    this.setState({
      userId: val
    });
  },

  storeEmailId(e) {
    this.abortReq();
    const input = e.target, val = input.value.trim();
    this.setState({
      emailId: val
    });
  },

  storePhoneNo(e) {
    this.abortReq();
    const input = e.target, val = input.value.trim();
    this.setState({
      phoneNo: val
    });
  },

  storeBdas(values) {
    this.abortReq();
    this.setState({
      bda: values
    });
  },
  abortReq() {
    if(applianceInfoPromise && applianceInfoPromise._abort) {
      applianceInfoPromise._abort();
      this.setState({
        busy: false
      });
    }
  },
  routeToDashboard(appliance) {
    const {application: app} = this.props,
        {deviceId, serial} = appliance;
    if(deviceId)
      app.route(`/appliance/${deviceId}/dashboard/${serial ? serial + "/" : ""}`);
    else
      app.route(`/appliance/dashboard/${serial}`);
  },
  go() {
    ApplianceService.setShowMoreDevicesButton(true)
    const {selectedTab, inputTypeDeviceInfo, inputTypeCustInfo} = this.state,
        {application: app} = this.props,
        {notifications} = app,
        createErrMsg = (arr, type) => {
          return arr.length === 1
          ? `${type} : ${arr.join()} 
              is not compatible with SubZero Connected Portal.`
          : `${type}s : ${arr.join(", ")} 
              are not compatible with SubZero Connected Portal.`;
        };
    // let retVal = [];
    let retVal;

    if(selectedTab === 0) {
      // this is for customer Info
      if(inputTypeCustInfo === "emailId" && this.state.emailId.trim().length) {
        retVal = {
          emailId: this.state.emailId
        };
        // retVal = ["emailId", this.state.emailId];
      } else if(inputTypeCustInfo === "userId" && this.state.userId.trim().length) {
        retVal = {
          userId: this.state.userId
        };
        // retVal = ["userId", this.state.userId];
      } else if(inputTypeCustInfo === "phoneNo" && this.state.phoneNo.trim().length) {
        retVal = {
          phoneNo: this.state.phoneNo
        };
        // retVal = ["phoneNo", this.state.phoneNo];
      } else {
        retVal = null;
      }
    } else if (selectedTab === 1) {
      // this is for device Info
      if(inputTypeDeviceInfo === "serialNo" && this.state.serialNo.length) {
        retVal = {
          serialNo: this.state.serialNo
        };
        // retVal = ["serialNo", ...this.state.serialNo];
      } else if(inputTypeDeviceInfo === "deviceId" && this.state.deviceId.length) {
        retVal = {
          deviceId: _.map(this.state.deviceId, devId =>
            devId.toString().toLocaleLowerCase()
          )
        };
        // retVal = ["deviceId", ...this.state.deviceId];
      } else if(inputTypeDeviceInfo === "BDA" && this.state.bda.length) {
        retVal = {
          bda: this.state.bda
        };
        // retVal = ["BDA", ...this.state.bda];
      }
    } else {
      retVal = null;
    }
    // const [searchBy, ...ids] = retVal;
    if(retVal) {
      this.abortReq();
      this.setState({
        busy: true
      });
      applianceInfoPromise = ApplianceService.getApplianceInfo(retVal, true);
      applianceInfoPromise.then(ApplianceService.modifyAppliances)
      .then(res => {
        console.log("Test",res);
        if(res && res.length>0){
          const appliance = res[0];
          if(!appliance.pumaVersion && appliance.type && appliance.deviceId){
            DashboardService.fetchGroupDetails(res[0]).then((groupDetails)=>{
              this.setApplianceData(retVal, res, groupDetails);
            })
          }
          else{
            this.setApplianceData(retVal, res);
          }
        }
      }).catch(errRes => {
        console.error(errRes);
        if (errRes.name === "AbortError") {
          return;
        }
        this.setState({
          busy: false
        });
        const {serialNo, deviceId, bda} = retVal;
        if(serialNo || deviceId || bda) {
          if (typeof errRes.json === "function") {
            errRes.json().then(err => {
              if (err.cause.message.indexOf("not compatible with SubZero") > 0)
                notifications.error(err.cause.message, Config.notificationTimeOut);
              else
                notifications.error("Error fetching appliance information.");
            });
          }else {
            notifications.error("Error fetching appliance information.");
          }
        } else {
          notifications.error("Error fetching appliance information.");
        }
      });
      // app.route("/dashboard/"+ "searchBy="+searchBy+"&"+searchBy+"="+ids.join());
    } else {
      const errorMessage = selectedTab === 0
          ? "Please enter either User ID, Email ID or Phone Number."
          : "Please enter either Serial Number, Device ID or BDA.";
      notifications.error(errorMessage);
    }
  },

  setApplianceData(retVal, res, groupDetails){
    const userSessoinId =  Util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
    applianceSessionID = Util.uniqueSessionId();
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param;
    sessionStorage.setItem("applianceSessionID", applianceSessionID);
    const additionalData = {};
    if(groupDetails){
      additionalData.firmwareVersion =  groupDetails.FirmwareVersion;
    }
    AI.trackUserLoginEvent("APPLIANCE_SESSION", {model: res[0].model,
      serial: res[0].serial, softwareVersions: JSON.stringify(res[0].versions), 
      pumaVersion: res[0].pumaVersion,
      ...additionalData,
      userSessoinId, applianceSessionID, 
      CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : ""});
    const {serialNo, deviceId, bda} = retVal;
    this.setState({
      busy: false
    });
    if(res.length > 0) {
      if(serialNo || deviceId || bda) {
        const len = serialNo
            ? serialNo.length
            : (deviceId
              ? deviceId.length
              : (bda
                  ? bda.length
                  : 0));

        if(len === res.length) {
          ApplianceService.setCurrentApplianceInfos(res);
          this.routeToDashboard(res[0]);
        } else {
          let unavailable = [],
              warnMessage = "";

          if(serialNo && serialNo.length > 0) {
            unavailable = _.filter(serialNo, serial => {
              return !_.find(res, appliance => serial === appliance.serial);
            });
            warnMessage = createErrMsg(unavailable, "Serial Number");
          } else if(deviceId && deviceId.length > 0) {
            unavailable = _.filter(deviceId, devId => {
              return !_.find(res, appliance => devId === appliance.deviceId);
            });
            warnMessage = createErrMsg(unavailable, "Device Id");
          } else {
            unavailable = _.filter(bda, bda => {
              return !_.find(res, appliance => bda === appliance.bda);
            });
            warnMessage = createErrMsg(unavailable, "BDA");
          }

          if(unavailable.length > 0) {
            notifications.warn(warnMessage, Config.notificationTimeOut);
          }
          ApplianceService.setScrollTopOfApplianceList(0);
          ApplianceService.setCurrentApplianceInfos(res);
          this.routeToDashboard(res[0]);
        }
      } else {
        ApplianceService.setCurrentApplianceInfos(res);
        this.routeToDashboard(res[0]);
      }
    } else {
      if(serialNo || deviceId || bda) {
        let errorMessage = "";
        if(serialNo && serialNo.length > 0) {
          errorMessage = createErrMsg(serialNo, "Serial Number");
        } else if(deviceId && deviceId.length > 0) {
          errorMessage = createErrMsg(deviceId, "Device Id");
        } else {
          errorMessage = createErrMsg(bda, "BDA");
        }
        notifications.error(errorMessage);
      }else {
        notifications.error("Error fetching appliance information.");
      }
    }
  },

  updateForm(form) {
    this.setState({
      phoneFrm: form
    });
  },
  renderCustomerInfo() {
    const {userId, emailId, phoneNo, busy, inputTypeCustInfo, phoneFrm, serviceStatus} = this.state,
        {subzeroGroupAccount} = serviceStatus,
        tooltip = subzeroGroupAccount == false 
            ? "Search disabled because Sub-Zero Group Accounts service is down" 
            : "",
            clazz = subzeroGroupAccount === false ? "disabled" : "";
    let buttonClass = busy
          ? "button primary anim "
          : "button primary ",
        iconClass = busy ? "icon spin icon-loader" : "";
    return (
      <div className="form">
        <div className="radio-groups">
          <div className="radio-group" title={tooltip}>
            <input id="rdaEmailId" type="radio" name="rdoCustInfo"
                checked={inputTypeCustInfo === "emailId"}
                onChange={this.selectInputTypeCustInfo.bind(this, "emailId")} 
                disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaEmailId" className={clazz}>Email ID</label>
          </div>
          <div className="radio-group" title={tooltip}>
            <input id="rdaUserId" type="radio" name="rdoCustInfo"
                checked={inputTypeCustInfo === "userId"}
                onChange={this.selectInputTypeCustInfo.bind(this, "userId")} 
                disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaUserId" className={clazz}>User ID</label>
          </div>
          <div className="radio-group" title={tooltip}>
            <input id="rdaPhoneNo" type="radio" name="rdoCustInfo"
                checked={inputTypeCustInfo === "phoneNo"}
                onChange={this.selectInputTypeCustInfo.bind(this, "phoneNo")} 
                disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaPhoneNo" className={clazz}>Phone Number</label>
          </div>
        </div>
        {(inputTypeCustInfo === "emailId"
          ? <input type="text"
              onKeyPress={this.handleEnterPress}
              value={emailId}
              onChange={this.storeEmailId}
              placeholder="Email ID" 
              disabled={subzeroGroupAccount === false}/>
          : null
        )}
        {(inputTypeCustInfo === "userId"
          ? <input type="text"
              onKeyPress={this.handleEnterPress}
              value={userId}
              onChange={this.storeUserId}
              placeholder="User ID" 
              disabled={subzeroGroupAccount === false}/>
          : null
        )}
        {(inputTypeCustInfo === "phoneNo"
          ? <Form rules={rules} onChange={this.updateForm}>
              <input type="text"
                name="phoneNo"
                onKeyPress={this.handleEnterPress}
                defaultValue={phoneNo}
                onChange={this.storePhoneNo}
                placeholder="Phone Number" 
                disabled={subzeroGroupAccount === false}/>
            </Form>
          : null
        )}
        <div className="actions">
          <button onClick={this.go} className={buttonClass} 
              title={tooltip}
              disabled={inputTypeCustInfo === "phoneNo" && phoneFrm && !phoneFrm.valid || subzeroGroupAccount == false}>
          <i className={iconClass} />
            Search
          </button>
        </div>
      </div>
    );
  },
  renderDeviceInfo() {
    const {serialNo, deviceId, bda, busy, serviceStatus} = this.state,
        {subzeroGroupAccount} = serviceStatus,
        tooltip = subzeroGroupAccount == false 
        ? "Search disabled because Sub-Zero Group Accounts service is down" 
        : "",
        clazz = subzeroGroupAccount === false ? "disabled" : "";
    let buttonClass = busy ? "button primary anim" : "button primary",
        iconClass = busy ? "icon spin icon-loader" : "";
    return (
      <div className="form">
        <div className="radio-groups">
        <div className="radio-group" title={tooltip}>
            <input id="rdaSerialNo" type="radio" name="rdoDeviceInfo"
                checked={this.state.inputTypeDeviceInfo === "serialNo"}
                onChange={this.selectInputTypeDeviceInfo.bind(this, "serialNo")} 
                disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaSerialNo" className={clazz}>Serial Number</label>
          </div>
          <div className="radio-group" title={tooltip}>
            <input id="rdaDeviceId" type="radio" name="rdoDeviceInfo"
                checked={this.state.inputTypeDeviceInfo === "deviceId"}
                onChange={this.selectInputTypeDeviceInfo.bind(this, "deviceId")} 
                disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaDeviceId" className={clazz}>MAC ID</label>
          </div>
          <div className="radio-group" title={tooltip}>
            <input id="rdaBDAId" type="radio" name="rdoDeviceInfo"
              checked={this.state.inputTypeDeviceInfo === "BDA"}
              onChange={this.selectInputTypeDeviceInfo.bind(this, "BDA")} 
              disabled={subzeroGroupAccount === false}/>
            <label htmlFor="rdaBDAId" className={clazz}>BDA</label>
          </div>
        </div>
        {(this.state.inputTypeDeviceInfo === "serialNo"
          ? <div className="fields">
              <MultiValueInput ref="serialNoInput" values={serialNo}
                onChange={this.storeSerialNo}
                placeholder="Serial Numbers" 
                isDisabled={subzeroGroupAccount === false}/>
            </div>
          : null)}
        {(this.state.inputTypeDeviceInfo === "deviceId"
          ? <div className="fields">
              <MultiValueInput ref="deviceIdInput" values={deviceId}
                validator={{
                  "pattern": /^[0-9A-Fa-f]*$/,
                  "message": "MAC IDs accept only hex values [0-9 A-F a-f]"
                }}
                onChange={this.storeDeviceId}
                placeholder="MAC IDs" 
                isDisabled={subzeroGroupAccount === false}/>
            </div>
          : null)}
        {(this.state.inputTypeDeviceInfo === "BDA"
          ? <div className="fields bda">
              <MultiValueInput ref="bdaInput" values={bda}
                  validator={{
                    "pattern": /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/,
                    "message": "BDA accept hex values [0-9 A-F a-f] & colon eg. xx:xx:xx:xx:xx:xx"
                  }}
                  onChange={this.storeBdas}
                  placeholder="BDAs" 
                  isDisabled={subzeroGroupAccount === false}/>
            </div>
          : null)}
        <div className="actions">
          <button onClick={this.go} className={buttonClass} 
              disabled={subzeroGroupAccount == false}
              title={tooltip}>
          <i className={iconClass} />
            Search
          </button>
        </div>
      </div>
    );
  },
  renderServiceStatus() {
    const {serviceStatus} = this.state,
      {subzeroGroupAccount, azureCloud} = serviceStatus,
      subzeroSnowTicketSub = "Sub-Zero Group Accounts Status Unavailable",
      subzeroSnowTicketBody = "Sub-Zero Group Accounts Status is not available because Sub-Zero services are unavailable. Please review the issue.",
      subzeroUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=${subzeroSnowTicketSub}
          &body=${subzeroSnowTicketBody}`,
      azureSnowTicketSub = "Azure Cloud Status Unavailable",
      azureSnowTicketBody = "Azure Cloud Status is not available because Azure Cloud services are unavailable. Please review the issue.",
      azureUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=${azureSnowTicketSub}
          &body=${azureSnowTicketBody}`;
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param,
        crmTicketId = CRM_TICKET_ID ? `&CRM_TICKET_ID=${CRM_TICKET_ID}` : "",
        aaUrl = `${Config.answerAdvisorLink}/multi-brand/azure-cloud-service-unavailable-in`;
    return (
      <div>
        {serviceStatus
        ? [<div key="service-status" className="service-status">
          <div id="subzero-group">
            {
              subzeroGroupAccount == undefined
                ? <a href={subzeroUpdateLink} target="_blank">
                    <img src={`${imgBasePath}/srvc_uknw.svg`} id="subzero-uknw"/>
                  </a>
                : (subzeroGroupAccount === true
                  ? <img src={`${imgBasePath}/srvc_up.svg`} id="subzero-up"/>
                  : <img src={`${imgBasePath}/srvc_down.svg`} id="subzero-down"/>
                )
            }
            <div className="title" id="subzero">Sub-Zero Group Accounts</div>
          </div>
          <div id="azure-cloud" className="azure-section">
            {
              azureCloud == undefined
                ? <a href={azureUpdateLink} target="_blank">
                    <img src={`${imgBasePath}/srvc_uknw.svg`} id="azure-uknw"/>
                  </a>
                : (azureCloud === true
                  ? <img src={`${imgBasePath}/srvc_up.svg`} id="azure-up"/>
                  : <img src={`${imgBasePath}/srvc_down.svg`} id="azure-down"/>
                )
            }
            <div className="title" id="azure">Azure Cloud</div>
          </div>
        </div>,
        <div className="link-section">
          {
            subzeroGroupAccount === false
              ? <div key="aalink" className="subzero-aa" 
                  onClick={() => Util.trackAAEvent(Config.answerAdvisorLink)}>
                  <a href={Config.answerAdvisorLink} 
                      target="_blank" id="subzero-aa">Answer Advisor</a>
                </div>
              : null
          }
          {
            azureCloud === false
              ? <div className="azure-aa" onClick={() => Util.trackAAEvent(aaUrl)}>
                  <a href={`${Config.answerAdvisorLink}/multi-brand/azure-cloud-service-unavailable-in`} 
                      target="_blank" id="azure-aa">Answer Advisor</a>
                </div>
              : null 

          }
        </div> 
      ]
        : null}
      </div>
    )
  },
  render() {
    return (
      <div className="view appliance-search-view layout">
        <div className="main-content">
          <div className="appliance-search-panel">
            <div className="header">
              SEARCH FOR AN APPLIANCE
            </div>
            <Tabs className="content" activeTab={1} onSelectionChanged={this.selectedTabType}>
              {
                UserPermissions.allowViewCustomerInfo() ? (
                  <Tabs.Tab key="customer-info" title="Customer Info">
                  {this.renderCustomerInfo()}
                </Tabs.Tab>): null 
              }
              <Tabs.Tab key="device-info" title="Device Info">
                {this.renderDeviceInfo()}
              </Tabs.Tab>
            </Tabs>
            <div>
              {this.renderServiceStatus()}
            </div>
          </div>
        </div>
      </div>
    );
  }
});
