import React from "react";
import ReactClass from "create-react-class";
import { ResponsiveTabs } from "../../widgets";
import { StatefulToolTip } from "react-portal-tooltip";
import Pagination from "../../widgets/Pagination.jsx";

const deafultItems = [{
  label: "Status",
  value: "N/A"
}];

const TabComponent = ReactClass({
  getInitialState() {
    return {
      pageIndex: 0
    };
  },
  renderTable(tabData) {
    if (tabData.length > 0) {
      const {pageIndex} = this.state;
      return tabData.map(({label, value, valueTypeColorName, liClassName, tooltip, pages}, i) => {
        if(label === "Paginated Data") {
            return pages[pageIndex] && pages[pageIndex].map((rec, k) => {
              const dividerClass = `${!k && tabData[0].label !== "Paginated Data" 
                  ? "paginated-divider" 
                  : !k && i !== 0 && tabData[i].label === "Paginated Data" 
                  ? "divider" : ""}`;
              return (<li key={`rec-${k}`} className={dividerClass}>
              <span className="label">
                {rec.label}
                {rec.tooltip
                  ? (<StatefulToolTip key={`extra-info-tooltip-${i}`} {...rec.tooltip.tooltipProps} parent={(<i className={`${rec.tooltip.icon} tooltip`} id={`extra-info-${i}`}></i>)}>
                      <div className="current-state tooltip-text">
                        {rec.tooltip.text}
                      </div>
                    </StatefulToolTip>)
                  : null}
              </span>
              <span className={`value`} style={{color: rec.valueTypeColorName}}>{rec.value ? rec.value : "N/A"}</span>
              {/* <span className={`value ${valueTypeClassName}`}>{/\d/.test(value) === true ? parseInt(value) : value}</span> */}
            </li>)
            })
        }
        return (<li key={`attribute-${i}`} className={`${liClassName}`}>
          <span className="label">
            {label}
            {tooltip
              ? (<StatefulToolTip key={`extra-info-tooltip-${i}`} {...tooltip.tooltipProps} parent={(<i className={`${tooltip.icon} tooltip`} id={`extra-info-${i}`}></i>)}>
                  <div className="current-state tooltip-text">
                    {tooltip.text}
                  </div>
                </StatefulToolTip>)
              : null}
          </span>
          <span className={`value`} style={{color: valueTypeColorName}}>{value ? value : "N/A"}</span>
          {/* <span className={`value ${valueTypeClassName}`}>{/\d/.test(value) === true ? parseInt(value) : value}</span> */}
        </li>)
      })
    } else {
      return (<li>
        <span className="label">Status</span>
        <span className="Value">N/A</span>
      </li>)
    }
  },
  onChangePage(pageIndex) {
    this.setState({ pageIndex: pageIndex });
  },
  renderPagination(list) {
    let noOfPages = 0;
    list.forEach((attr, j) => {
      if(attr.numberOfPages)
      {
        noOfPages = attr.numberOfPages;
      }
    })
    if(noOfPages) {
      return <Pagination  
          onChangePage={this.onChangePage} limit={noOfPages}></Pagination>
    }
  },
  render() {
    const { data, className, name } = this.props;
    return (
      <div className={`tab-wrapper ${className}`}>
        {/* {data.length > 0 */}
        { Object.keys(data).length == 0
          ?
          (<div className="empty">
            No Data Available.
          </div>)
         : "error" in data
           ?
              (<div className="error">
              {data.error.error_message}
            </div>)
            : (<ResponsiveTabs activeTab={0} name={name} onSelectionChanged={this.onChangePage}>
              {
                data.map((tab, i) => {
                  return (<ResponsiveTabs.Tab key={`tab-${tab.tabName.replace(" ", "_")}-${i}`} title={tab.tabName} className="TitleCase">
                    <ul className="list">
                      {this.renderTable(tab.attributes)}
                      {this.renderPagination(tab.attributes)}
                    </ul>
                  </ResponsiveTabs.Tab>)
                })
              }
            </ResponsiveTabs>)
          }
      </div>
    )
  }
});

TabComponent.defaultProps = {
  data: [],
  className: "",
  name: 'tab'
}

export default TabComponent;