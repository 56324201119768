export default {
  appnamespace: "connected-appliance-dashboard",
  /*
    Mock API url
  */
  // apiServerUrl: "http://localhost:7001",

  /*
    Backend API URL
    Run Backend API Server on your local machine (excluding port 8080)
    Other wise used deployed Backend API url.
  */
  // apiServerUrl: "http://localhost:8081",
  // apiServerUrl: "https://testszdashboard.com",

  apiServerUrl: "https://testszdashboard.com",
  apiUrl: "/api",
  baseDir: "",
  auth: {
    token: null
  },
  snowTicketTargetEmail: "connecteddiagnostics@subzero.com",
  feedbackEmail: "connecteddiagnostics@subzero.com",
  resourcesLink: {
    scLink: "https://service.subzero.com",
    aaLink: "https://www.subzero-wolf.com/assistance/answers/corp",
    ckLink: "https://service.subzero.com/tools/connectedstatuslookup?Query=Serial&SerialNumber=",
    helpLink:"https://www.subzero-wolf.com/assistance/answers/corp/multi-brand/using-the-connected-appliance-portal"
  },
  enableAgentAssist: false,
  agentAssistUrl: "https://agentassist.subzero.bruviti.com",
  answerAdvisorLink: "https://www.subzero-wolf.com/assistance/answers/corp",

  aaLink: "https://km.subzero.com/advisor/showcase?project=Sub-Zero&case=",
  // Answer Advisor url
  aaUrl: "http://km.subzero.com/advisor/bookmarksecure",

  // Azure Configuration Cnstants
  clientId: "cff3e935-3f10-4658-b6a9-35049fd8e6fa",
  loginResource: "8f332c67-1992-4e2a-b333-514976c7fc78",
  tenant: "6c637512-c417-4e78-9d62-b61258e4b619",
  redirectUri: "https://testszdashboard.com",
  // instance: "https://login.microsoftonline.com/",
  authority: "https://login.microsoftonline.com",
  cacheLocation: "localStorage",
  popUp: true,
  loadFrameTimeout: 60000,
  fallbackPollingInterval: 30000,
  storeAuthStateInCookie: true,

  experimentalFeatures: false,
  graphMissingdataColor: "#FF5E63",
  graphMissingdataIntervalSeconds: "360",

  /*
    You can change Configuration Object dinamically using Set function
    with Configuration Object.
  */
  set(Config) {
    let self = this;
    console.log("Before", Object.assign({}, self));
    Object.keys(Config).forEach(prop => {
      if(prop !== "set") {
        self[prop] = Config[prop];
      }
    });

    /*
     * if you want run application on local machine then uncomment following statment,
     * And set apiServerUrl (use Backend API URL, not Mock API url).
    */
    // self.redirectUri = "http://localhost:8080";
    console.log("After", Object.assign({}, self));
  },
  // This notificationTimeOut for clear sticky notification message after 30s.
  notificationTimeOut: (30 * 1000)
  // enableMultipleAuthFlow:true,
  // scKey:"CAP-TEST",//CAP-TEST, CAP-STAGE, CAP-PROD
  // scLoginUrl:"https://beta.service.subzero.com"
};
