/* global console setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import {mapping} from "./icon-mapping";
import OvenCounts from "./OvenCountsComponent.jsx";
import OvenCookingModes from "./OvenCookingModesComponent.jsx";

const DataComponent = ReactClass({
  render() {
    const {data} = this.props;
    return (Object.keys(data).map((dataVal, i) => {
      return (
        <li key={i}>
          <span className="label">{dataVal}</span>
          <span className="value" dangerouslySetInnerHTML={{__html: data[dataVal]}}></span>
        </li>
      );
    }));
  }
});


export default ReactClass({
  displayName: "UnitStatisticsInfo",
  getInitialState() {
    return {
      selectedMode: 0
    };
  },
  onSelectMode(selected) {
    this.setState({
      selectedMode: selected
    });
  },
  renderESeriesData(widgetSubVal) {
    const {selectedMode} = this.state;
    let dataComp = null,
        event = widgetSubVal[selectedMode],
        {Data} = event;
    if(typeof Data === "object") {
      dataComp = (
        <ul className="col_5 data-order">
          <DataComponent data={Data} />
        </ul>
      );
    }
    return [
      // Modes
      <OvenCookingModes widgets={this.props.widgets}
          onSelectMode={this.onSelectMode} selectedMode={selectedMode}/>,
      // Data
      dataComp];
  },
  renderBINGISeriesData(widget, subVal, idx) {
    const {title, widgets} = this.props;
    return (
      title === "Lower Oven" || title === "Upper Oven"
        ? <OvenCounts Counts={widgets.Counts} />
        : [(
          <li key={idx}>
            <span className="title">{subVal}</span>
          </li>
        ), (Object.keys(widget[subVal]).map((key, j) => {
          return (
            <li key={j}>
              <span className="label">{key}</span>
              <span className="value">{widgets[subVal][key]}</span>
            </li>
          );
        })
        )
        ]);
  },
  render: function() {
    const {title, widgets} = this.props,
        rows = typeof widgets === "object"
          ? Object.keys(widgets).map((subValues, i) => {
            if (typeof widgets[subValues] === "object" && Array.isArray(widgets[subValues])) {
              return this.renderESeriesData(widgets[subValues]);
            } else if(typeof widgets[subValues] === "object") {
              return this.renderBINGISeriesData(widgets, subValues, i);
            }
            return (
              <li key={i}>
                <span className="label">{subValues}</span>
                <span className="value">{widgets[subValues]}</span>
              </li>
            );
          })
        : (<li>
            <span className="label">{title}</span>
            <span className="value">{widgets}</span>
          </li>);
    return (
      <div className="unit-statistics-info">
          {typeof widgets === "object"
          ? (<div className="header">
              {mapping[title] ? <img src={mapping[title]}/> : ""}
              <span className="title">{title}</span>
            </div>) : ""}
        <div className="content">
          <ul className="row">
            {rows}
          </ul>
        </div>
      </div>
    );
  }
});

