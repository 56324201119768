/* global console setTimeout clearTimeout*/
import React from "react";
import ReactClass from "create-react-class";
import {LoadingIndicator} from "../../components/widgets";
import ApplianceList from "./ApplianceList.jsx";

export default ReactClass({
  displayName: "ApplianceLeftNav",
  getInitialState() {
    return {
      applListSidePanel: false
    };
  },
  toggleApplianceList() {
    this.setState({
      applListSidePanel: !this.state.applListSidePanel
    });
  },
  closeApplListSidePanel() {
    this.setState({
      applListSidePanel: false
    });
  },
  render() {
    const {appliances, application, selectedDeviceId, selectedSerial, selected
      , onApplianceSelected, fetchingAppliances, fetchApplianceInfo} = this.props,
        content = (fetchingAppliances
          ? <LoadingIndicator />
          : <ApplianceList ref="applianceList" appliances={appliances}
            application={application} selectedDeviceId={selectedDeviceId}
            selectedSerial= {selectedSerial}
            onApplianceSelected={onApplianceSelected}
            fetchApplianceInfo={fetchApplianceInfo}
            >
          </ApplianceList>);

    return (
    [<div key="applianceList" className={"sidebar appliance" +
        ( this.state.applListSidePanel ? " in": "") }>
      <div className="sidebar-header">Appliances Owned</div>
      <div className="slide-list" onClick={this.toggleApplianceList}>
        <i className={`icon icon-chevron-${this.state.applListSidePanel ? "left" : "right"}`}></i>
      </div>
      {content}
    </div>,
    (this.state.applListSidePanel
      ? <div key="mask-navigation" className="mask-navigation mask-appliance"
        onClick={this.closeApplListSidePanel}></div>
      : null)]);
  }
});
