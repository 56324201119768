/* global setTimeout */
import React from "react";
import ReactClass from "create-react-class";
import AuthComponent from "../modules/auth/AuthComponent.jsx";
import Config from "../config";
import MultipleAuthFlowComponent from "./auth/MultipleAuthFlowComponent";
import AuthService from '../modules/auth/Service';
import util from "../components/util";

export default ReactClass({
  displayName: "LandingView",
  getInitialState: function() {
    return {};
  },
  componentDidMount(){
    console.log('PROPS:',this.props);
    const {application} = this.props;
    // if(window.location.href.includes('token')){
    //   util.setLoggedInUsingSc(true);
    //   let mUrl = window.location.href.replace("/#/",'');
    //   let url = new URL(mUrl);
    //   if(url.search){
    //     let token = url.searchParams.get('token');
    //     if(token){
    //       //validate received token  
    //       console.log('Redirect from SC, token received');  
    //       /**flow cancelled**/
    //       //this.validateScToken(token);
    //       Config.auth.token = token;
    //       application.route('/appliance-search');
    //     }
    //     else{
    //       console.log('No redirect from SC');  
    //     }
    //   }
    // }
  },

  render: function() {

    const {application} = this.props;
    const CapAuthComponent = (Config.enableMultipleAuthFlow==='true'? MultipleAuthFlowComponent:AuthComponent);
    return (
      <div className="view landing-view">
        <CapAuthComponent application={application} />
      </div>
    );
  }
});
