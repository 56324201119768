/* global console history setTimeout*/
import React from "react";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import ApplianceInfo from "../common/ApplianceInfoComponent.jsx";

import {Service as ApplianceService, ApplianceLeftNav} from "../appliance";
import {Dashboard} from "../../components/dashboard";
import mobileAppsLogs from "./channel-config";
import Config from "../../config";
import {AuthService} from "../auth";
import ConsumerAppInfoComponent from "./ConsumerAppInfoComponent.jsx";
import Util from "../../components/util";
import {Tabs} from "../../components/widgets"
import * as _ from "lodash";
import ResourcesComponent from "../common/Resources.jsx";
import Service from "./Service";

import mock from "./mockData"

const getReadableDate = utcMS => {
  return Util.formatDate(utcMS, "MM.dd.yyyy ", false);
};

const EMPTY_DASH = {
  name: "Simple",
  datalayer: {
    name: "None",
    channels: []
  },
  widgets: []
};

const LogInfo = ReactClass({
  displayName: "LogIngo",
  render() {
    const {log, header, subheader, description} = this.props;
    return (
      <div className="log-info">
        <h1>{_.get(log, header)}</h1>
        <div>
          <span className="label">Device</span>
          <h3 className="value">{_.get(log, subheader)}</h3>
        </div>
        <div>
          <span className="label">Error Date</span>
          <h5 className="value">{getReadableDate(_.get(log, description))}</h5>
        </div>
      </div>
    )
  }
})

const LogsList = ReactClass({
  displayName: "LogsList",
  renderLogsList() {
    const {allLogs, selectedLogIdx} = this.props;
    return allLogs.map((log, idx) => {
      return (
        <li key={idx}>
          <div className={`${selectedLogIdx === idx ? "selected" : ""}`}
              onClick={() =>{
                this.props.onMove(idx);
              }}><LogInfo log={log} header="appLogType" subheader="appInfo.phoneModel" 
              description="timestamp"/></div>
        </li>
      )
    })
  },
  render() {
    return (
      <div>
        <ul>
          {this.renderLogsList()}
        </ul>
      </div>
    );
  }
});


const DeviceList = ReactClass({
  displayName: "DeviceList",
  render() {
    const { props: { data, selected, onClick }} = this;
    return (
      <ul className="device-list">
        {
          data && data.map((device, idx) => (<li key={`device-${idx}`} className={`${selected === idx ? "selected" : ""}`} onClick={() => {
            onClick && onClick(idx)
          }}>{device.friendly_name}</li>))
        }
      </ul>
    );
  }
});

const UserNotificationSettings = ReactClass({
  displayName: "UserNotificationSettings",
  render() {
    const { props: { settingsList }} = this,
      displayValue = (value) => {
        switch(typeof value) {
          case "boolean":
            return (value === true
                ? <i className="icon icon-check check"></i>
                : <i className="icon icon-x cross"></i>);
          default:
            return <span>{value}</span>;
        }
      };

    return (
      <div className="widget">
        <div className="header">
          <span>User Notification Settings</span>
        </div>
        <div className="content">
          <ul className="ul-notification-settings">
            {
              Object.keys(settingsList).map((notify, i) => {
                return (<li key={`notification-${i}`}>
                  <label>{notify}</label>
                  { displayValue(settingsList[notify]) }
                </li>)
              })
            }
          </ul>
        </div>
      </div>
    );
  }
});

export default ReactClass({
  displayName: "MobileAppsLogs",
  isHardRefreshFlag: false,
  getInitialState() {
    return {
      // Appliance List needed following 3 objects
      fetchingAppliances: false,
      appliances: [],

      busy: false,
      idx: 0,
      dashboardConfig: EMPTY_DASH,
      exportUrl: "",
      allLogs: [],
      currentAppIdx: 0,
      userNotificationSettings: [],
      currentSelectedDevice: -1,
      loading: false,
      flag: true
    };
  },
  componentWillMount() {
    const Appliances = ApplianceService.getCurrentApplianceInfos(),
        {route: {params: {selectedDeviceId}}} = this.props;
    if(Appliances && Appliances.length > 0) {
      this.init(Appliances, selectedDeviceId)
    } else {
      this.isHardRefreshFlag = true;
      if(selectedDeviceId) {
        this.setState({
          busy: true
        });
        ApplianceService.getApplianceInfoFromDeviceId(selectedDeviceId).then(infos => {
          if(infos.length > 0) {
            this.setState({
              busy: false,
              appliances: infos
            });
            ApplianceService.setCurrentApplianceInfos(infos);
            this.init(infos, selectedDeviceId)
            this.setDashboardSettings();
          } else {
            this.routeToApplianceSearch();
          }
        }).catch(err => {
          this.routeToApplianceSearch();
          throw err;
        });
      } else {
        this.routeToApplianceSearch();
      }
    }
  },

  init(Appliances, selectedDeviceId) {
    const serialNumber = Appliances
    .filter(appl => appl.deviceId === selectedDeviceId)
    .reduce((_, currAppl) => currAppl.serial, "");
    let consumerAppParams = `serialNumber=${serialNumber}&deviceId=${selectedDeviceId}`;
    // consumerAppParams = `serialNumber=1392243R`;

    this.setState({
      fetchingAppliances: false,
      appliances: Appliances
    });
    const promiseToken = AuthService.getAPIToken(),
        {apiServerUrl, apiUrl} = Config;
    return promiseToken.then(authRes => {
      this.setState({
        exportUrl: `${apiServerUrl}${apiUrl}/consumerapp/logexport?${consumerAppParams}&token=Bearer ${authRes.accessToken}`
      });
    });
  },

  setDashboardSettings() {
    const currentAppliance = this.getCurrentAppliance(),
        promiseConfig = mobileAppsLogs(currentAppliance, this.state.idx),
        self = this;
    self.setState({
      busy: true
    });
    promiseConfig.then(config => {
      self.setState({
        dashboardConfig: config
      });
      this.copyDashConfig = Object.assign({}, config);
      const sub = self.dashboardRef.DataLayer.subscribe("/consumer/appLogs", data => {
        console.log("app logs - ", data)
        // data will be all mobile app logs .. [N] logs
        self.setState({
          allLogs: data,
          flag: true,
          busy: false
        });
        // sub.close();
        self.showLogAtIndex(0);
      }, err => {
        console.log("Error while fetching app logs - ", { err })
        const { consumerAppsLogs } = mock;

        // self.dashboardRef.DataLayer.publish("/consumer/appLogs", consumerAppsLogs)

        self.setState({
          flag: false,
          busy: false
        });
      });
    });
  },

  componentDidMount() {
    if(!this.isHardRefreshFlag) {
      this.setDashboardSettings();
    }
  },

  componentDidUpdate() {
    const {currentAppIdx, allLogs} = this.state,
        logData = allLogs.length > 0 ? allLogs[currentAppIdx] : null;
    if(logData) {
      this.dashboardRef && this.dashboardRef.DataLayer.publish("/consumer/appLogAtIndex", logData);
    }
  },

  showLogAtIndex(index) {
    const {dashboardConfig, allLogs} = this.state,
        {accessPoints} = allLogs[index];
    if(accessPoints && !accessPoints.length) {
      dashboardConfig.widgets = dashboardConfig.widgets
          .filter(w => w.id !== "consumerWifiScanList");
    } else {
      dashboardConfig.widgets = this.copyDashConfig.widgets;
    }
    this.setState({
      flag: false
    });
    setTimeout(() => {
      this.setState({
        currentAppIdx: index,
        dashboardConfig,
        flag: true
      });
    }, 100);
  },

  // Appliance List - need following 5 functions
  routeToApplianceSearch() {
    const {application: app} = this.props;
    app.route("/appliance-search");
  },
  selecteAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  renderApplianceList() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={selectedDeviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selecteAppliance}
      fetchingAppliances={this.state.fetchingAppliances}/>;
  },

  back() {
    history.back();
  },
  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {selectedDeviceId}}} = this.props,
        selectAppIdx = appliances.map(a => a.deviceId).indexOf(selectedDeviceId);

    return appliances[selectAppIdx];
  },
  notifyExport() {
    this.props.application.notifications.success("The exported file will be in your download folder after download.");
  },
  renderLoadingSpinner(msg) {
    return <div className="loading spin-loader">
      <div className="_text-center anim">
        <i className="icon icon-loader spin"></i>
      </div>
      {msg ? (<p className="_text-center">{msg}</p>) : null}
    </div>
  },

  fetchUserNotificationSettings(deviceId) {
    this.setState({
      loading: true
    });
    Service.fetchUserNotificationSettings(deviceId)
    .then(settings => {
      this.setState({
        userNotificationSettings: settings,
        currentSelectedDevice: 0,
        loading: false
      });
    })
    .catch(err => {
      console.log("Error - ", err)
      this.setState({
        userNotificationSettings: [],
        currentSelectedDevice: -1,
        loading: false
      });
    });
  },

  changeTabSelection(idx) {
    const { state: { userNotificationSettings, currentAppIdx }, props: {route: {params: {selectedDeviceId}}} } = this;
    if(idx === 1 && userNotificationSettings && userNotificationSettings.length == 0) {
      this.fetchUserNotificationSettings(selectedDeviceId);
    } else if(idx === 0) {
      this.showLogAtIndex(currentAppIdx)
    }
  },
  render() {
    const {busy, flag, dashboardConfig, idx, exportUrl, currentAppIdx, allLogs, userNotificationSettings, currentSelectedDevice, loading} = this.state,
        {application} = this.props,
        appliance = allLogs.length > 0 ? allLogs[currentAppIdx].applianceInfo : null,
        currentAppliance = this.getCurrentAppliance();
        const { experimentalFeatures: IS_FEATURE_SHOW } = Config;

    const notificationSettings =  currentSelectedDevice > -1
        ? (userNotificationSettings && userNotificationSettings[currentSelectedDevice]?.notification_settings) || {}
        : {};

    return (
      <div className="view consumer-apps-logs-view layout">
        {this.renderApplianceList()}
        <div className="main-content">
          {currentAppliance
            ? <ResourcesComponent currentAppliance={currentAppliance}/>
            : null
          }
          <div className="consumer-apps-logs">
            <div className="row content-header">
              <div className="return-section" onClick={this.back}>
                <i className="icon icon-chevron-left" />
                <span>Return</span>
              </div>
              <p>MOBILE APPS LOGS</p>
              <a type="button" className="button inline primary btn-export"
                 onClick={this.notifyExport} disabled={allLogs && allLogs.length === 0}
                 href={exportUrl} download>Export Logs</a>
            </div>
            <Tabs activeTab={0} className={`tabs`} onSelectionChanged={this.changeTabSelection}>
              <Tabs.Tab key={`error_type`} title={"Error Type"}>
                <div className="content-section">
                  {
                    allLogs.length > 0
                      ? <div className="tile-section">
                        <div className="heading-section">
                          {/* <h1>Error Type</h1> */}
                          <h5>Select an error to view details.</h5>
                        </div>
                        <div className="logs-list">
                          <LogsList selectedLogIdx={currentAppIdx} onMove={this.showLogAtIndex} allLogs={this.state.allLogs} />
                        </div>
                      </div>
                      : null
                  }
                  <div className="app-log-info">
                    {allLogs.length > 0
                      ? <ConsumerAppInfoComponent appliance={appliance}
                        application={application} />
                      : null
                    }
                    {
                      flag === true
                        ? <div>
                          <div className={busy ? "hidden" : ""}>
                            <Dashboard ref={x => this.dashboardRef = x} config={dashboardConfig} />
                          </div>
                          {busy
                            ? this.renderLoadingSpinner("Loading Consumer Apps logs...")
                            : null}
                        </div>
                        : allLogs.length
                          ? this.renderLoadingSpinner("Loading Consumer Apps logs...")
                          : <div className="empty">No Records Found</div>
                    }
                  </div>
                </div>
              </Tabs.Tab>
              {IS_FEATURE_SHOW === true
              ? <Tabs.Tab key={`devices`} title={"Devices"}>
                <div className="content-section user-notification-settings">
                  <div className="tile-section">
                    <div className="heading-section">
                      <h5>Select an device to view settings.</h5>
                    </div>
                    <div className="logs-list">
                      {
                        loading
                          ? this.renderLoadingSpinner()
                          : (<DeviceList selected={currentSelectedDevice} data={userNotificationSettings} onClick={(idx) => {
                            this.setState({
                              currentSelectedDevice: idx
                            })
                          }} />)
                      }
                    </div>
                  </div>
                  <div className="app-log-info">
                    <div className="container">
                      {allLogs.length > 0
                        ? <ConsumerAppInfoComponent appliance={appliance}
                          application={application} />
                        : null
                      }
                      {
                        loading
                          ? this.renderLoadingSpinner()
                          : (<UserNotificationSettings settingsList={notificationSettings} />)
                      }
                    </div>
                  </div>
                </div>
              </Tabs.Tab>
              : null
  }
   </Tabs>
          </div>
        </div>
      </div>
    );
  }
});
