/* console Promise */
import View from "./View.jsx";

export default [
  {
    path: "/appliance/:selectedDeviceId/logs",
    controller: context => {
      return {
        component: View,
        context
      };
    }
  },
  {
    path: "/appliance/logs/:selectedSerial",
    controller: context => {
      return {
        component: View,
        context
      };
    }
  }
];
