/* global setTimeout clearTimeout */
import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import Config from "../../../config";

import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";

import PollingFrequency from "./Component.jsx";

const Widget = AsSubscribedComponent(ReactClass({
  displayName: "PollingFrequencyWidget",
  getInitialState() {
    return {
      data: {
        frequency: ""
      },
      oldFrequency: "",
      isPolling: false
    };
  },
  onChangeFrequency(frequency) {
    const {oldFrequency} = this.state,
        {onAction} = this.props;
    this.setState({
      isPolling: true
    });

    onAction("setPollingFrequency", frequency);

    this.timeout = setTimeout(() => {
      this.setState({
        isPolling: false
      });

      const promise = onAction && onAction("getPollingFrequency", frequency);
      promise.then(res => {
        const latestFrequency = res.frequency;
        if(frequency === latestFrequency) {
          this.setState({
            data: {
              frequency: latestFrequency
            },
            oldFrequency: frequency
          });
        }
      });
    }, 10000);
  },
  render() {
    const {Header, Content} = WidgetPanel,
        {data: {frequency}, isPolling} = this.state;
    return (
      <WidgetPanel {...this.props}>
        <Header>
          <span>{this.props.title}</span>
        </Header>
        <Content>
          <PollingFrequency frequency={frequency} isPolling={isPolling}
              onChangeFrequency={this.onChangeFrequency} />
        </Content>
      </WidgetPanel>
    );
  },
  onData(data, channel) {
    const latestFrequency = +data.frequency || "",
        {context: application} = this.props,
        {oldFrequency, isPolling} = this.state;

    if(!isPolling) {
      this.setState({
        channel: channel,
        data: {
          frequency: latestFrequency
        },
        oldFrequency: latestFrequency
      });
    } else {
      clearTimeout(this.timeout);
      if(oldFrequency !== latestFrequency) {
        this.setState({
          channel: channel,
          data: {
            frequency: latestFrequency
          },
          oldFrequency: latestFrequency,
          isPolling: false
        });
        application.notifications.success("Successfully set polling frequency.");
      }else {
        this.setState({
          data: {
            frequency: oldFrequency
          },
          oldFrequency,
          isPolling: false
        });
        application.notifications.error("Failed to set polling frequency for this device.");
      }
    }
  }
}));

const Info = {
  id: "appliance-dashboard:polling-frequency",
  name: "Appliance Polling Frequency",
  type: Widget,
  description: "Shows current appliance polling frequency",
  icon: "",
  tags: "appliance Polling Frequency"
};

export default {
  Info,
  Widget
};
