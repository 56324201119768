import ApplianceList from "./ApplianceList.jsx";
import ApplianceLeftNav from "./ApplianceLeftNav.jsx";
import DiagnosticsPanel from "./DiagnosticsPanel.jsx";
import Service from "./Service";
import ApplianceInfoComponent from "../../components/dashboard-widgets/appliance-info/Component.jsx";
import DiagnosticStatusComponent from "./diagnostic-status";

export {
  DiagnosticStatusComponent,
  ApplianceInfoComponent,
  ApplianceList,
  DiagnosticsPanel,
  Service,
  ApplianceLeftNav
};
