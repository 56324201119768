/* console Promise */
import View from "./View.jsx";

export default [{
  path: "/appliance/:selectedDeviceId?/faults/:selectedSerial?",
  controller: context => {
    return {
      component: View,
      context
    };
  }
},
{
  path: "/appliance/:selectedDeviceId/connected/faults/:projectName",
  controller: context => {
    const ctx = Object.assign(context, {connected: true});
    return {
      component: View,
      context: ctx
    };
  }
}];
