import Config from "../../../config";
import UserPermissions from "../../auth/UserPermissions";

const { experimentalFeatures: IS_FEATURE_SHOW } = Config;

export const widgets = {
  "applianceInfo": {
    "id": "applianceInfo",
    "type": "appliance-dashboard:applianceInfo",
    "title": "Appliance Information",
    "cols": 4,
    "channel": ["/appliance/info", "/appliances/diagnostics/connectivity", "/appliances/deviceStatus", "/appliance/current-state/data-point"],
    "panelClass": "appliance-info-widget"
  },
  "connectivity": {
    "id": "connectivity",
    "type": "appliance-dashboard:connectivity",
    "title": "Connected Module",
    "cols": 4,
    "channel": ["/appliances/diagnostics/connectivity", "/appliances/deviceStatus"],
    "panelClass": "connectivity-status-widget"
  },
  "refrigeratorzone": {
    "id": "refrigeratorzone",
    "type": "appliance-dashboard:part-status",
    "title": "Refrigerator Zone",
    "cols": 4,
    "icon": "images/set_point.svg",
    "channel": "/appliance/refrigerator/appliance-data",
    "filterOn": "refrigeratorZone"
  },
  "timezone": {
    "id": "timezones",
    "type": "appliance-dashboard:time-zone",
    "title": "Time Zone",
    "cols": 4,
    "channel": "/appliance/time-zone"
  },
  "errorcodes": {
    "id": "errorcodes",
    "type": "appliance-dashboard:error-codes",
    "title": "Error Codes",
    "cols": 4,
    "channel": "/appliance/error-codes"
  },
  "airPurification": {
    "id": "airPurification",
    "type": "appliance-dashboard:part-status",
    "title": "Air Purification",
    "cols": 4,
    "icon": "images/airFilter_icon.svg",
    "channel": "/appliance/refrigerator/appliance-data",
    "filterOn": "airPurification"
  },
  "icemaker": {
    "id": "icemaker",
    "type": "appliance-dashboard:part-status",
    "title": "Ice Maker",
    "cols": 4,
    "icon": "images/night_ice.svg",
    "channel": "/appliance/refrigerator/appliance-data",
    "filterOn": "iceMaker"
  },
  "freezerzone": {
    "id": "freezerzone",
    "type": "appliance-dashboard:part-status",
    "title": "Freezer Zone",
    "cols": 4,
    "icon": "images/set_point.svg",
    "channel": "/appliance/refrigerator/appliance-data",
    "filterOn": "freezerZone"
  },
  "waterfilter": {
    "id": "waterfilter",
    "type": "appliance-dashboard:waterfilter",
    "title": "Water Filter",
    "cols": 4,
    "panelClass": "water-filter-widget",
    "channel": "/appliance/refrigerator/water-filter"
  },
  "pollingFrequency": {
    "id": "pollingFrequency",
    "type": "appliance-dashboard:polling-frequency",
    "title": "Sample Frequency",
    "cols": 4,
    "panelClass": "polling-frequency-widgets",
    "channel": "/appliance/pollingFrequency"
  },
  "accountInfo": {
    "id": "accountInfo",
    "type": "appliance-dashboard:accountInfo",
    "title": "Account Information",
    "cols": 4,
    "channel": "/user/info",
    "panelClass": "account-info-widget"
  },
  "networkConnInfo": {
    "id": "networkConnInfo",
    "type": "appliance-dashboard:networkConnInfo",
    "title": "Wireless Network",
    "cols": 4,
    "channel": "/appliance/networkConn-info",
    "panelClass": "network-conn-info"
  },
  "mobileNotification": {
    "id": "mobileNotification",
    "type": "appliance-dashboard:mobileNotification",
    "title": "Mobile Notification",
    "cols": 4,
    "channel": "/appliance/mobile-notification",
    "panelClass": "mobile-notification"
  },
  "provisioningReport": {
    "id": "provisioningReport",
    "type": "appliance-dashboard:provisioningReport",
    "title": "Provisioning Report",
    "cols": 4,
    "channel": "/appliance/provisioning-report",
    "panelClass": "provisioning-report"
  },
};

const mapConnStatus = {
  /* Hide dummy data widget - If IS_FEATURE_SHOW is false then hide pollingFrequency */
  "online": {
    "refrigerator": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "oven": ["applianceInfo", "connectivity", "timezone", "errorcodes",  "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "dishwasher": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "generic": ["applianceInfo", "connectivity", "timezone", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "dual fuel": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"]
  },
  // removed "pollingFrequency" widget
  "offline": {
    "refrigerator": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "oven": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "dishwasher": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "generic": ["applianceInfo", "connectivity", "timezone", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"],
    "dual fuel": ["applianceInfo", "connectivity", "timezone", "errorcodes", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport"]
  },
  // removed "errorcodes" widget
  "ready": {
    "refrigerator": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "oven": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dishwasher": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "generic": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dual fuel": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification","provisioningReport", "timezone"]
  },
  // Added "connectivity" widget, refer to jira ticket: SUB:955
  "capable": {
    "refrigerator": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "oven": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dishwasher": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "generic": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dual fuel": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"]
  },
  // Added "connectivity" widget, refer to jira ticket: SUB:955
  "unavailable": {
    "refrigerator": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "oven": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dishwasher": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "generic": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"],
    "dual fuel": ["applianceInfo", "connectivity", "accountInfo", "networkConnInfo", "mobileNotification", "provisioningReport", "timezone"]
  },
  "unknown": {
    "refrigerator": ["applianceInfo", "provisioningReport"],
    "oven": ["applianceInfo", "provisioningReport"],
    "dishwasher": ["applianceInfo", "provisioningReport"],
    "generic": ["applianceInfo", "provisioningReport"],
    "dual fuel": ["applianceInfo", "provisioningReport"]
  }
};

export const getCommonWidget = (appliance, perApplianceType) => {
  // in case no config for appliance type exists
  perApplianceType = perApplianceType || {};
  perApplianceType.hideWidgets = perApplianceType.hideWidgets || [];

  const {connectedStatus, name = "", type} = appliance,
      commonObjects = mapConnStatus[connectedStatus ? connectedStatus.toLowerCase() : "unknown"],
      deviceType = Object.keys(commonObjects)
        .filter(deviceType => name.toLowerCase().indexOf(deviceType) > -1)
        .reduce(type => type),
      commonWidgets = commonObjects[deviceType]
        .filter(w => !!w)
        .filter(each => perApplianceType.hideWidgets.indexOf(each) === -1)
        .map(widgetName => widgets[widgetName]) || [];

  return commonWidgets;
};

const modelMapping = [
  {
    "modelNo": "BI-30U",
    "model": ["30U", "006", "018", "043", "048"]
  }, {
    "modelNo": "BI-30UG",
    "model": ["30UG", "012", "024", "044", "049", "301"]
  }, {
    "modelNo": "BI-36U",
    "model": ["36U", "001", "013", "040", "045"]
  }, {
    "modelNo": "BI-36UG",
    "model": ["36UG", "010", "022", "041", "046", "304"]
  }, {
    "modelNo": "BI-36UID",
    "model": ["36UID", "310"]
  }, {
    "modelNo": "BI-36UFD",
    "model": ["36UFD", "039", "042", "047"]
  }, {
    "modelNo": "BI-36UFDID",
    "model": ["36UFDID", "317"]
  }, {
    "modelNo": "BI-42UFD",
    "model": ["42UFD", "307", "326"]
  }, {
    "modelNo": "BI-42UFDID",
    "model": ["42UFDID", "313"]
  }, {
    "modelNo": "BI-36S",
    "model": ["36S", "009", "021", "025", "030", "053", "056"]
  }, {
    "modelNo": "BI-42S",
    "model": ["42S", "007", "019", "028", "033", "054", "057"]
  }, {
    "modelNo": "BI-42SD",
    "model": ["42SD", "008", "020", "029", "034", "059", "061", "305", "318", "311", "322", "327", "331", "336", "338"]
  }, {
    "modelNo": "BI-42SID",
    "model": ["42SID", "035", "037", "063", "065", "306", "319", "312", "323", "328", "332", "340", "342"]
  }, {
    "modelNo": "BI-48S",
    "model": ["48S", "003", "015", "027", "032", "055", "058"]
  }, {
    "modelNo": "BI-48SD",
    "model": ["48SD", "002", "014", "026", "031", "060", "062", "308", "320", "314", "324", "329", "333", "337", "339"]
  }, {
    "modelNo": "BI-48SID",
    "model": ["48SID", "036", "038", "064", "066", "309", "321", "315", "325", "330", "334", "341", "343"]
  }, {
    "modelNo": "BI-36R",
    "model": ["36R", "004", "016", "052"]
  }, {
    "modelNo": "BI-36F",
    "model": ["36F", "005", "017", "302"]
  }, {
    "modelNo": "BI-36RG",
    "model": ["36RG", "011", "023", "067", "303", "335"]
  }, {
    "modelNo": "BI-36RID",
    "model": ["36RID", "316"]
  }, {
    "modelNo": "DO30-2",
    "model": ["DO30-2"]
  }, {
    "modelNo": "SO30-2",
    "model": ["SO30-2"]
  }, {
    "modelNo": "DO30-2",
    "model": ["DO30-2B"]
  }, {
    "modelNo": "SO30-2",
    "model": ["SO30-2B"]
  }, {
    "modelNo": "DO30TM",
    "model": ["DO30TM"]
  }, {
    "modelNo": "SO30TM",
    "model": ["SO30TM"]
  }, {
    "modelNo": "DO30CM",
    "model": ["DO30CM"]
  }, {
    "modelNo": "SO30CM",
    "model": ["SO30CM"]
  }, {
    "modelNo": "DO30PM",
    "model": ["DO30PM"]
  }, {
    "modelNo": "SO30PM",
    "model": ["SO30PM"]
  }, {
    "modelNo": "DW2450",
    "model": ["DW2450"]
  }, {
    "modelNo": "DW2450WS",
    "model": ["DW2450WS"]
  }, {
    "modelNo": "IC-18FI",
    "model": ["IC18FI", "2001", "2040"]
  }, {
    "modelNo": "IC-24FI",
    "model": ["IC24FI", "2002", "2041"]
  }, {
    "modelNo": "IC-36FI",
    "model": ["IC36FI", "2037", "2042"]
  }, {
    "modelNo": "IC-30FI",
    "model": ["IC30FI", "2004"]
  }, {
    "modelNo": "IC-24R",
    "model": ["IC24R", "2003"]
  }, {
    "modelNo": "IC-30R",
    "model": ["IC30R", "2005"]
  }, {
    "modelNo": "IC-30RID",
    "model": ["IC30RID", "2006"]
  }, {
    "modelNo": "IC-36RID",
    "model": ["IC36RID", "2007"]
  }, {
    "modelNo": "IC-36R",
    "model": ["IC36R", "2036"]
  }, {
    "modelNo": "IC-24CI",
    "model": ["IC24CI", "2039"]
  }, {
    "modelNo": "IC-24C",
    "model": ["IC24C", "2038"]
  }, {
    "modelNo": "ID-24F",
    "model": ["ID24F", "2008"]
  }, {
    "modelNo": "ID-24FI",
    "model": ["ID24FI", "2009"]
  }, {
    "modelNo": "ID-30F",
    "model": ["ID30F", "2014"]
  }, {
    "modelNo": "ID-30FI",
    "model": ["ID30FI", "2015"]
  }, {
    "modelNo": "ID-24R",
    "model": ["ID24R", "2010"]
  }, {
    "modelNo": "ID-27R",
    "model": ["ID27R", "2011"]
  }, {
    "modelNo": "ID-30RP",
    "model": ["ID30RP", "2017", "2031"]
  }, {
    "modelNo": "ID-30R",
    "model": ["ID30R", "2016"]
  }, {
    "modelNo": "ID-36R",
    "model": ["ID36R", "2020"]
  }, {
    "modelNo": "ID-36RP",
    "model": ["ID36RP", "2021"]
  }, {
    "modelNo": "ID-30C",
    "model": ["ID30C", "2012"]
  }, {
    "modelNo": "ID-30CI",
    "model": ["ID30CI", "2013"]
  }, {
    "modelNo": "ID-36CI",
    "model": ["ID36CI", "2019"]
  }, {
    "modelNo": "ID-36C",
    "model": ["ID36C", "2018"]
  }, {
    "modelNo": "IT-30FI",
    "model": ["IT30FI", "2024", "2043"]
  }, {
    "modelNo": "IT-30R",
    "model": ["IT30R", "2025"]
  }, {
    "modelNo": "IT-30RID",
    "model": ["IT30RID", "2026"]
  }, {
    "modelNo": "IT-36R",
    "model": ["IT36R", "2029"]
  }, {
    "modelNo": "IT-36RID",
    "model": ["IT36RID", "2030"]
  }, {
    "modelNo": "IT-30CI",
    "model": ["IT30CI", "2022", "2032"]
  }, {
    "modelNo": "IT-30CIID",
    "model": ["IT30CIID", "2023", "2033"]
  }, {
    "modelNo": "IT-36CI",
    "model": ["IT36CI", "2027", "2034"]
  }, {
    "modelNo": "IT-36CIID",
    "model": ["IT36CIID", "2028", "2035"]
  }, {
    "modelNo": "UW-24",
    "model": ["UW24"]
  }, {
    "modelNo": "BW-30",
    "model": ["BW30"]
  }, {
    "modelNo": "IW-18",
    "model": ["IW18", "2401"]
  }, {
    "modelNo": "IW-24",
    "model": ["IW24", "2402"]
  }, {
    "modelNo": "IW-30",
    "model": ["IW30", "2403"]
  }, {
    "modelNo": "IW-30R",
    "model": ["IW30R", "2404"]
  }, {
    "modelNo": "IW-30CI",
    "model": ["IW30CI", "2405"]
  }, {
    "modelNo": "PRO-48",
    "model": ["PRO4850"]
  }, {
    "modelNo": "PRO-48G",
    "model": ["PRO4850G"]
  }, {
    "modelNo": "PRO-36",
    "model": ["PRO3650"]
  }, {
    "modelNo": "PRO-36G",
    "model": ["PRO3650G"]
  }, {
    "modelNo": "Unknown",
    "model": ["Unknown"]
  }
];

export const getModelName = modelNo => {
  let modelArr = modelMapping.filter(el => {
    if (el.model.indexOf(modelNo) !== -1)
      return el;
  });

  return modelArr[0] ? modelArr[0].modelNo : modelNo;
};

export const getWidgets = (appliance, configWidgets, perApplianceType, isOtherWidgetsVisible = false) => {
  let {model, type} = appliance,
      modell = model ? model.trim() : "Unknown".trim(),
      modelName = getModelName(modell).trim(),
      configWidgetObj = configWidgets[modelName] || [],
      commonWidgets = getCommonWidget(appliance, perApplianceType),
      allWidgets = commonWidgets;

  if(isOtherWidgetsVisible === true) {
    const status = appliance.connectedStatus ? appliance.connectedStatus.toLowerCase() : "",
        otherWidgets = ((!status || status === "unavailable")
          ? []
          : configWidgetObj
            .map(widgetName => widgets[widgetName])
            .filter((widget, idx) => {
              if(widget)
                return widget;
              console.warn("Widget Not found in configuration : ", configWidgetObj[idx]);
            })
          );
    allWidgets.push(...otherWidgets);
  }
  const finalWidgets = allWidgets.filter((widget)=>{
    if(widget.id==='accountInfo'){
      if(UserPermissions.allowViewAccountInfo()){
        return widget
      }
      else{
        console.warn("Widget should not be shown to user : ", widget);
      }
    }
    else{
      return widget;
    }
  })
  const renamer = perApplianceType && perApplianceType.widgetTitles;
  return !renamer ? finalWidgets : finalWidgets.map(each => {
    each.title = renamer[each.id] || each.title;
    return each;
  });
};
