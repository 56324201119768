/* global history setTimeout*/
import ReactClass from "create-react-class";
import React from "react";
import Config from "../../config";
import {ApplianceLeftNav, Service as ApplianceService} from "../appliance";
import {AuthService} from "../auth";
import ApplianceInfo from "../common/ApplianceInfoComponent.jsx";
import {FaultService} from "./";
import FaultsComponent from "./FaultsComponent.jsx";
import {CSVLink} from "../common/react-csv";
import ResourcesComponent from "../common/Resources.jsx";


export default ReactClass({
  displayName: "Faults",
  getInitialState() {
    return {
      // Appliance List needed following 3 objects
      fetchingAppliances: false,
      appliances: [],

      faults: [],
      busy: false,

      exportUrl: "",
      errorMessage: null
    };
  },
  getAuthToken() {
    const user = AuthService.getAuth();
    return user ? user.token : Config.auth.token;
  },
  setExportUrl() {
    // This code for getting Latest token from Adal and
    // Create exportUrl and bind latest token to that url.
       
    const promiseToken = AuthService.getAPIToken(),
        {connected, route: {params: {selectedDeviceId}}} = this.props,
        {apiServerUrl, apiUrl} = Config;
    
    if (connected) {
      promiseToken.then(authRes => {
        const exportPath = `errorexport?token=Bearer ${authRes.accessToken}&tzoffset=${(new Date().getTimezoneOffset()*-1)}`,
            exportUrl = `${apiServerUrl}${apiUrl}/appliances/${selectedDeviceId}/${exportPath}`;
        this.setState({exportUrl});
      });
    }
  },
  componentWillMount() {
    const Appliances = ApplianceService.getCurrentApplianceInfos(),
        {connected, route: {params: {selectedDeviceId}}} = this.props;
     
    if (Appliances && Appliances.length > 0) {
      this.setState({
        fetchingAppliances: false,
        appliances: Appliances
      });
      this.fetchFaults();
      this.setExportUrl();
    } else {
      if(connected && selectedDeviceId) {
        this.setState({
          fetchingAppliances: true,
          busy: true
        });
        ApplianceService.getApplianceInfoFromDeviceId(selectedDeviceId).then(infos => {
          if(infos.length > 0) {
            this.setState({
              fetchingAppliances: false,
              appliances: infos
            });
            ApplianceService.setCurrentApplianceInfos(infos);
            this.fetchFaults();
            this.setExportUrl();
          } else {
            this.routeToApplianceSearch();
          }
        }).catch(err => {
          this.routeToApplianceSearch();
          throw err;
        });
      } else {
        this.routeToApplianceSearch();
      }
    }
  },

  fetchFaults() {
    const {application, connected, route: {params: {selectedDeviceId}}} = this.props,
        notifications = application.notifications;

    this.setState({
      busy: true
    });
    FaultService[(connected) ? "fetchFaultsForConnected" : "fetchFaults"](selectedDeviceId).then(data => {
      this.setState({
        faults: data,
        busy: false
      });
    }).catch(err => {
      const errorMessage = "Error fetching appliance information";
      this.setState({
        busy: false,
        errorMessage
      });
      notifications.error(errorMessage);
    });
  },

  // Appliance List - need following 5 functions
  routeToApplianceSearch() {
    const {application: app} = this.props;
    app.route("/appliance-search");
  },
  selecteAppliance(deviceId, serial) {
    const {application: app} = this.props,
        projectName = ApplianceService.getSelectedCatProjectName();

    if(deviceId)
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/${deviceId}/dashboard/`);
    else
      app.route(`${projectName ? "/project/" + projectName : ""}/appliance/dashboard/${serial}/`);
  },
  renderApplianceList() {
    const {route: {params: {selectedDeviceId, selectedSerial}}} = this.props;
    return <ApplianceLeftNav appliances={this.state.appliances}
      application={this.props.application}
      selectedDeviceId={selectedDeviceId}
      selectedSerial={selectedSerial}
      onApplianceSelected={this.selecteAppliance}
      fetchingAppliances={this.state.fetchingAppliances} />;
  },

  back() {
    const {connected, application, route: {params: {projectName}}} = this.props;
    if(connected)
      application.route(`/project/${projectName}/history`);
    else
      history.back();
  },
  getCurrentAppliance() {
    const {appliances} = this.state,
        {route: {params: {selectedDeviceId, selectedSerial}}} = this.props,
        selectAppIdx = selectedDeviceId
            ? appliances.map(a => a.deviceId).indexOf(selectedDeviceId)
            : appliances.map(a => a.serial).indexOf(selectedSerial);

    return appliances[selectAppIdx];
  },
  notifyExport() {
    setTimeout(() => {
      this.props.application.notifications.success("Export started. Check your system downloads folder.")
    }, 500);
  },
  render() {
    const {faults, busy, exportUrl, errorMessage} = this.state,
        {application, connected} = this.props,
        totalFaults = faults.length,
        activeFaults = faults.filter(f => f["Active"].toLowerCase() === "yes").length,
        currentAppliance = this.getCurrentAppliance(),
        applianceInfo = currentAppliance
          ? ([<ResourcesComponent currentAppliance={currentAppliance}/>,
              <ApplianceInfo appliance={currentAppliance} application={application}></ApplianceInfo>
            ]
          )
          : null;

    return (
      <div className="view faults-view layout">
        {this.renderApplianceList()}
        <div className="main-content">
          {applianceInfo}
          <div className="view-table">
            <div className="row content-header">
              <div className="return-section" onClick={this.back}>
                <i className="icon icon-chevron-left" />
                <span>Return</span>
              </div>
              <p>Faults</p>
            </div>
            <div className="highlight row">
              <div className="total row">
                <label>Total Faults</label>
                <span className="totFaults">{totalFaults}</span>
              </div>
              <div className="total row">
                <label>Active Faults</label>
                <span className="actFaults">{activeFaults}</span>
              </div>
              <div className="actions">
                {(connected)
                  ?
                  <a type="button"
                    disabled={!(faults && faults.length > 0)}
                    onClick={this.notifyExport}
                    className="button inline primary btn-export" href={exportUrl} download>Export</a>
                  :
                  <CSVLink type="button"
                    disabled={!(faults && faults.length > 0)}
                    className="button inline primary btn-export"
                    data={faults}
                    filename={`${(currentAppliance) ? currentAppliance.serial + "_" : ""}faultLog.csv`}>Export</CSVLink>
                }
                
              </div>
            </div>
            <FaultsComponent appliance={currentAppliance} faults={faults} busy={busy} errorMessage={errorMessage}/>
          </div>
        </div>
      </div>
    );
  }
});
