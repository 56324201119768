/* global setTimeout, clearTimeout */
/*
const createObject = Object.create || (proto => {
  function T() {};
  T.prototype = proto;
  return new T();
});
const create = (prototype, props) => {
  const object = Object.create(prototype);
  Object.keys(props).forEach(k => {
    object[k] = props[k];
  });
  return object;
};
*/

import Storage from "store2";
import AI from "../appInsights";
import config from "../config";
import AnswerAdviser from "../modules/answer-adviser";

const EventEmitterProto = {
  on(event, handler) {
    const handlers = this.eventHandlers[event] || (this.eventHandlers[event] = []);
    handlers.push(handler);
    return {
      dispose() {
        const index = handlers.indexOf(handler);
        if(index !== -1) {
          handlers.splice(index, 1);
        }
      }
    };
  },
  once(event, handler) {
    let subs;
    subs = this.on(event, (...args) => {
      subs.dispose();
      handler(event, ...args);
    });
  },
  emit(event, ...args) {
    let handlers = this.eventHandlers[event] || [];
    handlers.forEach(h => {
      h(event, ...args);
    });
  }
};

export default {
  createEventEmitter() {
    return Object.create(EventEmitterProto, {
      eventHandlers: {
        value: {}
      }
    });
  },
  throttle: (callback, delay = 500) => {
    let timeoutId;
    return function(...args) {
      const scope = this;
      if(timeoutId) {
        window.clearTimeout(timeoutId);
      }
      timeoutId = window.setTimeout(() => {
        callback.call(scope, ...args);
      }, delay);
    };
  },
  pad(value, padding = "00") {
    const p = padding.substring(0, padding.length - (value + "").length);
    return p + value;
  },
  parseTemperature(value = 0) {
    let hex = (value || 0).toString(16).toUpperCase(),
        lowByte = parseInt(hex.substring(2), 16),
        highByte = parseInt(hex.substring(0, 2), 16);
    if(lowByte === 0xff) {
      return - (highByte ^ 0xff);
    }else if(lowByte === 0) {
      return highByte;
    }
    return null;
  },
  formatDate(date, format, utc = false) {
    const regexpDATEFORMAT = /yyyy|YYYY|yy|M+|D+|d+|HH|H|hh|h|mm|m|ss|s|a|ww|w|z/g,
        getFullYear = utc ? "getUTCFullYear" : "getFullYear",
        getYear = utc ? "getUTCYear" : "getYear",
        getMonth = utc ? "getUTCMonth" : "getMonth",
        getDate = utc ? "getUTCDate" : "getDate",
        getHours = utc ? "getUTCHours" : "getHours",
        getMinutes = utc ? "getUTCMinutes" : "getMinutes",
        getSeconds = utc ? "getUTCSeconds" : "getSeconds",
        getMilliseconds = utc ? "getUTCMilliseconds" : "getMilliseconds",
        setHours = utc? "setUTCHours": "setHours",
        setDate = utc ? "setUTCDate" : "setDate",
        getDay = utc ? "getUTCDay" : "getDay",

        padLeft = (str, max, char = " ") => {
          let len = max - str.length;
          if (len < 0) {
            return str;
          }
          while (len--) {
            str = char + str;
          }
          return str;
        },
        padZeros = (value, count = 2) => {
          return padLeft(value.toString(), count, "0");
        };

    let half = false;

    if (format && format[0] === "!") {
      half = true;
      format = format.substring(1);
    }

    if (typeof(date) === "number") {
      date = new Date(date);
    }

    if (!format) {
      return date[getFullYear]() + "-" +
          padZeros(date[getMonth]() + 1) + "-" +
          padZeros(date[getDate]()) + "T" +
          padZeros(date[getHours]()) + ":" +
          padZeros(date[getMinutes]()) + ":" +
          padZeros(date[getSeconds]()) + "." +
          padZeros(date[getMilliseconds](), 3) + "Z";
    }

    let h = date[getHours]();

    if (half) {
      if (h >= 12)
        h -= 12;
    }

    return format.replace(regexpDATEFORMAT, function(key) {
      let tmp;
      switch (key) {
        case "yyyy":
        case "YYYY":
          return date[getFullYear]();
        case "yy":
          return date[getYear]();
        case "MM":
          return padZeros(date[getMonth]() + 1);
        case "M":
          return (date[getMonth]() + 1);
        case "dd":
        case "DD":
          return padZeros(date[getDate]());
        case "d":
          return date[getDate]();
        case "HH":
        case "hh":
          return padZeros(h);
        case "H":
        case "h":
          return date[getHours]();
        case "mm":
          return padZeros(date[getMinutes]());
        case "m":
          return date[getMinutes]();
        case "ss":
          return padZeros(date.getSeconds());
        case "s":
          return date[getSeconds]();
        case "ms":
          return date[getMilliseconds]();
        case "w":
        case "ww":
          tmp = new Date(+date);
          tmp[setHours](0, 0, 0);
          tmp[setDate](tmp[getDate]() + 4 - (tmp[getDay]() || 7));
          tmp = Math.ceil((((tmp - new Date(tmp[getFullYear](), 0, 1)) / 8.64e7) + 1) / 7);
          return key === "ww" ? tmp.toString().padLeft(2, "0") : tmp;
        case "a":
          return date[getHours]() >= 12 ? "PM" : "AM";
        case "z":
          return date.toTimeString().slice(9);
      }
    }) + (utc ? " UTC" : "");
  },
  uniqueSessionId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },

  setUserSessionId(userSessionId){
    localStorage.setItem('userSessoinId',userSessionId);
  },

  getUserSessionId(){
    //If current session going on, just create new session
    //this is needed if user already logged in. This will
    //create new sessionId for ongoing session and keeps it
    //until user logs out
    const id = localStorage.getItem('userSessoinId');
    if(!id && config.auth.token){
      this.setUserSessionId(this.uniqueSessionId());
    }
    return localStorage.getItem('userSessoinId');
  },

  removeUserSessionId(){
    localStorage.removeItem('userSessoinId');
  },

  parseAccessToken(token) {
    let base64Url = token.split('.')[1],
        base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
        jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },
  trackAAEvent(aaUrl) {
    const userSessoinId =  this.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
        applianceSessionID = sessionStorage.getItem("applianceSessionID");
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param;
    AI.trackUserLoginEvent("ANSWER_ADVISOR_USED",{
      userSessoinId, 
      applianceSessionID: applianceSessionID ? applianceSessionID : "", 
      aaUrl: aaUrl,
      CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : ""});
  },
  trackPageWidgetEvent(name, target, deviceId) {
    const userSessoinId =  this.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
        applianceSessionID = sessionStorage.getItem("applianceSessionID");
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param;
    AI.trackUserLoginEvent(name, {
      target: target,
      userSessoinId:userSessoinId, 
      applianceSessionID: applianceSessionID ? applianceSessionID : "", 
      CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : "",
      deviceId: deviceId
    });
  },

  trackEventWithProperties(name, target, properties = {}) {
    const userSessoinId =  this.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
        applianceSessionID = sessionStorage.getItem("applianceSessionID");
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param;
    AI.trackUserLoginEvent(name, {
      target: target,
      userSessoinId:userSessoinId, 
      applianceSessionID: applianceSessionID ? applianceSessionID : "", 
      CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : "",
      ...properties
    });
  },
  
  OFFLINE_CONNECTIVITY_STATE_ID:"kCS",
  
  isLoggedInUsingSC(){
    return Storage.get('isLoggedInUsingSc');
  },
  
  setLoggedInUsingSc(loggedIn){
    Storage.set('isLoggedInUsingSc',loggedIn);
  },

  getSCAuthHeaders(){
    const store = Storage.namespace(config.appnamespace);
    const user = store.get("Auth");
    let roles = '';
    if(user.roles){
      roles = user.roles.join(',');
    }
    return {
      UserName:user.username,
      UserRoles:roles
    }
  },

  setSCRedirectUrl(url){
    Storage.set('scRedirectUrl',url);
  },

  getSCRedirectUrl(url){
    return Storage.get('scRedirectUrl');
  },
  getValueAtPathInObject(path, obj){
    for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        obj = obj[path[i]];
    };
    return obj;
  },
  isPumaDevice: function (type) {
    return type && type.substring(0, 2) !== "17";
  },
  getPumaFirmwareMajorVersion: function (version) {
    let versionPart;
    if (version.startsWith("szwcatfw")) {
      // Extracting the version part after 'szwcatfw_'
      versionPart = version.substring("szwcatfw_".length).split("-")[0];
    } else if(version.includes('-')) {
      // Extract the version number for the format like "8.1-16-g696cf33"
      versionPart = version.split("-")[0];
    }
    else{
      versionPart = version;
    }
    return parseInt(versionPart.split(".")[0], 10)
  },
  getOfflineStatePropertyModel(propId, panelIdx, panelTitle){
    return {
      "id" : propId,
      "name" : "Offline State-"+panelIdx,
      "displayName" : "Offline State-"+panelTitle,
      "type" : "OfflineState",
      "panelIdx" : panelIdx,
      "group" : "Unit Info",
      "color" : config.graphMissingdataColor || "#FF5E63",
      "valuesDescription":"1:Offline\r\n0:Online",
      "description":"Offline state",
      "valueMapping" : "1,Offline_0,Online"
    }
  }
};
