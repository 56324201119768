import LandingView from "../LandingView.jsx";

export default [
  {
    path: "/",
    controller: context => {
      return {
        component: LandingView,
        context
      };
    }
  }
];
