/* global console, Image history */
import React from "react";
import ReactClass from "create-react-class";
import {WidgetPanel, AsSubscribedComponent} from "ui-dashboard";
import AppliancePanel from "./Component.jsx";
import Config from "../../../config";
import {getModelName} from "../../../modules/dashboard/channel-config/widgets";
import * as _ from "lodash";
import {
  Service as ApplianceService
} from "../../../modules/appliance";
import HideByApplianceType from "../../../modules/dashboard/HideByApplianceType.jsx";
import DashboardService from "../../../modules/dashboard/Service";
import { StatefulToolTip } from "react-portal-tooltip";

import TabComponent from "./TabComponent.jsx";
import Util from "../../util";
import { cond } from "lodash";

const handler = () => {},
    titleMapping = {
      "ONLINE": "Connected Online",
      "OFFLINE": "Connected Offline",
      "READY": "Connected Ready",
      "CAPABLE": "Connected Capable"
    },
    imgBasePath = `${Config.baseDir}components/dashboard-widgets/appliance-info/appliance-images`,
    defaultImg = `${Config.baseDir}images`,
    azureCompStatus = {
      "GOOD": {
        image: `${imgBasePath}/azure_comp.svg`,
        title: `AZURE COMPATIBLE: Connected `,
        subTitle: `module is up to date.`
      },
      "BAD": {
        image: `${imgBasePath}/azure_blok.svg`,
        title: `AZURE BLOCKED: Connected `,
        subTitle: `module blocked. Submit IT ticket.`,
        snowTicketSubject: "Blocked",
        snowTicketIssue: ""
      },
      "UNKNOWN": {
        image: `${imgBasePath}/azure_dsbl.svg`,
        title: `AZURE DISABLED: Connected `,
        subTitle:`module disabled. Submit IT ticket.`,
        snowTicketSubject: "Disabled",
        snowTicketIssue: ""
      },
      "NOT_COMPATIBLE": {
        image: `${imgBasePath}/azure_ncmp.svg`,
        title: `AZURE NOT COMPATIBLE: Connected `,
        subTitle:`module update is available. Submit IT ticket.`,
        snowTicketSubject: "Update",
        snowTicketIssue: " Please update the connected module on this appliance."
      }
    },
    smartConnTitles = {
      "alexaUtilized" : "Alexa available for appliance",
      "alexaUtilized_no": "Uses Alexa",
      "alexaUtilized_updat": "Requires connected module update",
      "googleHomeUtilized": "Google Home available for appliance",
      "googleHomeUtilized_no": "Uses Google Home",
      "googleHomeUtilized_updat": "Requires connected module update",
      "amazonDRSUtilized": "Amazon Dash available for appliance",
      "amazonDRSUtilized_no": "Uses Amazon Dash",
      "amazonDRSUtilized_updat": "Requires connected module update"
    },
    amazonDRSTitleMapping = {
      "orderDatePlaced": "Order placed",
      "orderDateShipped": "Shipped",
      "orderEstimatedDeliveryDate": "Estimated delivery"
    },
    Widget = AsSubscribedComponent(ReactClass({
      displayName: "ApplianceInfoWidget",
      getInitialState: function() {
        return {
          data: {},
          dataPoint: {},
          consumerAppLogsCount: 0,
          isMobileLogsAvailable: false,
          azureCompatibility: "",
          imagePath: "",
          softwareVersionInfo: {},
          userDefinedDeviceName: "",
          smartConnectivityStatus: {},
          isLatestFirmwareVersion: null
        };
      },
      componentDidUpdate() {
        this.props.layout.layout();
      },
      routeToViewGraph() {
        const {onAction} = this.props,
            {data: {deviceId}} = this.state;
        onAction && onAction("route-to", `/appliance/${deviceId}/connected/graph`);
      },
      routeToGraphFilter() {
        const {onAction} = this.props,
            {data: {deviceId}} = this.state,
            uniqueId = Date.now();
            const url = `/#/appliance/${deviceId}/graph/select-data/${uniqueId}`;
            Util.trackPageWidgetEvent("WIDGET_CLICK", "Graph Widget", deviceId);
            const newTab = window.open();
            newTab.opener = null;
            newTab.location.assign(url);
        // onAction && onAction("route-to", `/appliance/${deviceId}/graph/select-data`);
      },
      routeToViewLogs() {
        const {onAction} = this.props,
            {data: {deviceId, serial}} = this.state;
        if(deviceId)
          onAction && onAction("route-to", `/appliance/${deviceId}/logs`);
        else if(serial)
          onAction && onAction("route-to", `/appliance/logs/${serial}`);
      },
      routeToViewAppLogs() {
        const {onAction} = this.props,
            {data: {deviceId}} = this.state;
        Util.trackPageWidgetEvent("WIDGET_CLICK", "Mobile Apps", deviceId);
        if(deviceId)
          onAction && onAction("route-to", `/appliance/${deviceId}/consumer-apps-logs`);
      },
      back() {
        const {onAction} = this.props,
            projectName = ApplianceService.getSelectedCatProjectName();
        onAction && onAction("route-to", `/project/${projectName}/history`);
      },
      exportToPdf() {
        const {data} = this.state;
        Util.trackPageWidgetEvent("PAGE_VISIT", "Appliance Data Export", data.deviceId);
        const {onAction} = this.props;
        onAction && onAction("exportPdf", this.state.data);
      },
      azureCompStatus() {
        console.log('Azure:', this.state,
        DashboardService.getCurrentApplianceDetails());
        const groupDetails = DashboardService.getCurrentApplianceDetails();
        const {azureCompatibility, data:{deviceId, serial, model, pumaVersion}} = this.state,
            noAzureCompatibility = <img src={`${defaultImg}/srvc_uknw.svg`}/>,
            noAzureCompSnowticketSub = "Azure Compatibility Status Unavailable",
            noAzureCompSnowTicketBody = `Azure Compatibility Status is not available because Azure Cloud services are unavailable. Please review the issue.%0ASN: ${serial} %0AModel: ${model} %0AMAC: ${deviceId || ''} %0AFW information: ${pumaVersion || groupDetails.FirmwareVersion || ''} %0AIssue customer is experiencing: %0A`,
            noAzureCompUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=${noAzureCompSnowticketSub}
            &body=${noAzureCompSnowTicketBody}`;
        if(!azureCompatibility) {
          return (
            <span className="azure-comp unknown-img">
              <a  href={noAzureCompUpdateLink} target='_blank'>
                <StatefulToolTip position="bottom" arrow="center" parent={noAzureCompatibility}
                    key="azure-unknown">
                  <div className="tooltip-section" key="tooltip-unknown">AZURE COMPATIBLE: Unknown</div>
                </StatefulToolTip>
              </a>
            </span>
          )
        }
        const azureCompSnowTicketSub = `Connected Module ${azureCompStatus[azureCompatibility].snowTicketSubject}`,
          azureCompSnowTicketBody = `${azureCompStatus[azureCompatibility].snowTicketIssue}`,
          azureCompImg = <img src={azureCompStatus[azureCompatibility].image}
              />,
          azureCompUpdateLink = `mailto:${Config.snowTicketTargetEmail}?subject=${azureCompSnowTicketSub}
            &body=Issue:${azureCompSnowTicketBody}
            %0D%0AMAC Address: ${deviceId}`;
        return (
          <span className="azure-comp">
            {
              (azureCompatibility === "GOOD")
                ? <span className="azure">
                  <StatefulToolTip position="bottom" arrow="center" parent={ azureCompImg }
                      key="azure-good">
                    <div className="tooltip-section" key="tooltip-good">
                      <div >{azureCompStatus[azureCompatibility].title}</div>
                      <div>{azureCompStatus[azureCompatibility].subTitle}</div>
                    </div>
                  </StatefulToolTip>
                  </span>
                : <a href={azureCompUpdateLink} target="_blank">
                    <StatefulToolTip position="bottom" arrow="center" parent={ azureCompImg }
                      key="azure-icon">
                      <div className="tooltip-section" key="tooltip-other">
                        <div>{azureCompStatus[azureCompatibility].title}</div>
                        <div>{azureCompStatus[azureCompatibility].subTitle}</div>
                      </div>
                  </StatefulToolTip>
                </a>
            }
          </span>
        );
      },
      renderSmartConnTitle(status) {
        const elements = status && status.map(a => {
          if (a.subscribed === false) {
            return {
              "custom": {
                [a.id]: "Not subscribed"
              }
            }
          }
          else {
            const {id, subscribed, orderDatePlaced, orderDateShipped, orderEstimatedDeliveryDate} = a;
            return {
              "custom": {
                [a.id]: "Subscribed"
              },
              orderDatePlaced,
              orderDateShipped,
              orderEstimatedDeliveryDate
            }
          }
        });
        return elements && elements.map(ele => {
          return this.renderTooltip(ele);
        })
      },
      renderTooltip(ele) {
        const attr = Object.keys(ele).map(key => {
          if (key == "custom") {
            const custom = ele[key];
             return Object.keys(custom).map(customKey => {
                return (
                  <div key={customKey} className="tooltip-attributes">
                  <span className="custom">{customKey}</span>
                  <label>{custom[customKey] || "N/A"}</label>
                </div>
                )
              });
          } else {
            return (
              <div key={key} className="tooltip-attributes">
                <span>{amazonDRSTitleMapping[key]}</span>
                <label>{ele[key] || "N/A"}</label>
              </div>
            );
          }
        });
        return attr;
      },
      smartConnectivityStatus() {
        const smartConnStatus = ["alexaUtilized", "googleHomeUtilized"], // amazonDRSUtilized - removed
            {smartConnectivityStatus, isLatestFirmwareVersion} = this.state;
        let img,
            status = this.renderSmartConnTitle(smartConnectivityStatus["drsSlotsStatus"]),
            amazonStatus = smartConnectivityStatus["drsSlotsStatus"] && smartConnectivityStatus["drsSlotsStatus"].length > 0;
        img = smartConnStatus.map((ele, i) => {
          const path = (typeof isLatestFirmwareVersion === "boolean" && isLatestFirmwareVersion === false) ? `${ele}_updat`
              : smartConnectivityStatus[ele] == true ? `${ele}_no` : ele,
              imgContainer = <img src={`${imgBasePath}/${path}.png`} id={`img${i}`}/>;
          return (
            <HideByApplianceType name={`applianceInfo::smartConnStatus${i}`}>
              <span className="smartConnStatus" key={i} id={`smartConnStatus${i}`}>
                <StatefulToolTip position="bottom" arrow="center" className="tooltip-section"
                    parent={ imgContainer } key="smart-icon">
                  <div className="tooltip-section" key="tooltip-smart">
                    {ele === "amazonDRSUtilized"
                      ? <div>
                          {amazonStatus && smartConnectivityStatus["amazonDRSUtilized"] == true
                            ? <div>
                                <p>Uses Amazon Dash</p>
                                <div>{status}</div>
                              </div>
                            : smartConnTitles[path]
                          }
                        </div>
                      : <div>{smartConnTitles[path]}</div>}
                  </div>
                </StatefulToolTip>
              </span>
            </HideByApplianceType>
          )
        })
        return img;
      },
      render: function() {
        const {Header, Content} = WidgetPanel,
            {data, consumerAppLogsCount = 0, isMobileLogsAvailable, userDefinedDeviceName,
              softwareVersionInfo, smartConnectivityStatus, dataPoint} = this.state,
            {connectedStatus} = data,
            projectName = ApplianceService.getSelectedCatProjectName(),
            {deviceId, type, serial} = data,
            connectedStatusIcon = <i className={`icon icon-wifi_icon conn-status ${connectedStatus}`}></i>,
            { experimentalFeatures: IS_FEATURE_SHOW } = Config;
        return (
          <WidgetPanel {...this.props}>
            <div className="app-info-widget widget-panel">
              <div className="header">
                <div>
                  <div id="app-info">{this.props.title}</div>
                  <div className="status-section">
                    {(!connectedStatus || connectedStatus === "UNAVAILABLE"
                      ? null
                      : <StatefulToolTip position="bottom" arrow="center" parent={connectedStatusIcon}
                        key="icon">
                        <div className="tooltip-section" key="i">{titleMapping[connectedStatus]}</div>
                      </StatefulToolTip>)}
                    {/* {this.azureCompStatus()} */}
                    {this.smartConnectivityStatus()}
                  </div>
                </div>
                {(projectName
                  ? <i className="button inline primary view-logs-btn icon icon-arrow-left"
                    onClick={this.back} title="Back"></i>
                  : null)}
                <HideByApplianceType name="applianceInfo::viewData">
                  {/* <button className="inline primary view-logs-btn" onClick={this.routeToViewGraph}
                    disabled={!deviceId} >Connected Data</button> */}
                  <button className="inline primary view-logs-btn" onClick={this.routeToGraphFilter}
                    disabled={!deviceId || !type} >Connected Data</button>
                </HideByApplianceType>
                {/* {(connectedStatus === "CAPABLE"
                  ? null
                  : <HideByApplianceType name="applianceInfo::mobileApps">
                    <button className="inline primary mobile-app-btn"
                      // disabled={!deviceId}
                      // Hide dummy data widget - If IS_FEATURE_SHOW is false then default disabled Mobile Apps button
                      // disabled={IS_FEATURE_SHOW ? !deviceId : true}
                      onClick={this.routeToViewAppLogs}>Mobile Apps
                      {(deviceId && consumerAppLogsCount)
                        ? <div className="consumer-logs-counts" title="In last 24 hrs">
                          {consumerAppLogsCount}
                        </div>
                        : null}
                    </button>
                  </HideByApplianceType>
                )} */}
                {/* <HideByApplianceType name="applianceInfo::export">
                  <button className="inline primary export-btn" onClick={this.exportToPdf}>
                    Export
                </button>
                </HideByApplianceType> */}
              </div>
              <div className="content">
                <AppliancePanel appliance={data} imagePath={this.state.imagePath}
                  softwareVersionInfo={softwareVersionInfo} userDefinedDeviceName={userDefinedDeviceName} />
              </div>
            </div>
            {
              /*Hide dummy data widget - If IS_FEATURE_SHOW is false then hide Current State Widget*/
              // IS_FEATURE_SHOW
                // ?
                (!(["OFFLINE", "UNAVAILABLE"].indexOf(connectedStatus) > -1)
                  ? ([<div key={`current-state-1`} className="separator"></div>,
                  <div key={`current-state-2`} className="data-point-widget widget-panel">
                    <div className="header">Performance History</div>
                    <div className="content">
                      <TabComponent data={dataPoint} name="sz-data-points-tab" />
                    </div>
                  </div>])
                  : null)
                // : null
            }
          </WidgetPanel>
        );
      },
      // gotoGraphView() {
      //   const {onAction} = this.props;
      //   onAction && onAction("route-to",
      //        "/graph-data/" + [this.state.data.serial, this.state.data.type].join(','));
      // },
      onData: function(data, channel) {
        if(channel === "/appliance/info") {
          DashboardService.fetchDeviceStatus(data).then(deviceStatus => {
            this.setState({
              azureCompatibility: deviceStatus.azureCompatibility
            })
          })
          const noImage = `${imgBasePath}/no_image.svg`;
          DashboardService.fetchApplianceInfoImage(data).then(res => {
            console.log('setting img -> ', res && res.imageUrl)
            DashboardService.setImageUrlData(res && res.imageUrl);
            this.setState({
              imagePath: (res && res.imageUrl) || noImage
            });
          }).catch(errRes => {
            DashboardService.setImageUrlData(noImage);
            this.setState({
              imagePath: noImage
            });
          });
          DashboardService.fetchApplianceName(data).then(res => {
            this.setState({
              userDefinedDeviceName: res.applianceName
            });
          }).catch(errRes => {
            this.setState({
              userDefinedDeviceName: ""
            });
          });
          DashboardService.fetchSwVersionStatus(data).then(res => {
            this.setState({
              softwareVersionInfo: res
            });
          }).catch(errRes => {
            this.setState({
              softwareVersionInfo: {}
            })
          });
          DashboardService.fetchSmartConnectivitysStatus(data).then(res => {
            this.setState({
              smartConnectivityStatus: res
            })
          }).catch(errRes => {
            this.setState({
              smartConnectivityStatus: {
                "googleHomeUtilized":false,
                "alexaUtilized":false,
                "amazonDRSUtilized":false,
                "drsSlotsStatus": []
              }
            })
          })
          DashboardService.setCurrentApplianceInfo(data);
          this.setState({
            channel: channel,
            data: data
          });
        } else if(channel === "/appliances/deviceStatus") {
          const {appliance, deviceStatus} = data,
              {deviceId} = appliance,
              {azureCompatibility, isLatestFirmwareVersion} = deviceStatus;
          this.setState({
            channel: channel,
            azureCompatibility,
            isLatestFirmwareVersion
          })
        } else if(channel === "/appliance/current-state/data-point") {
          let update = {}
          if (data && data.length > 0 && Object.keys(data)[0] != "error") {
            const groups = _.groupBy(data, 'tabName');

            update = _.keys(groups).reduce((groupingAcc, groupName) => {
              const tabRecords = groups[groupName];
              groupingAcc[groupName] = tabRecords.reduce((acc, record, i) => {
                record.attributes.forEach((val, i) => {
                  if(val?.pages && val?.pages.length) {
                    val.pages.map((ele) => {
                      const key = `${ele.label.split(' ').join('_')}`;
                      acc[key] = ele.value
                    })
                  }else {
                    const key = `${val.label.split(' ').join('_')}`;
                    acc[key] = val.value
                  }
                })
                return acc;
              }, {})
              return groupingAcc
            }, {})
          }
          DashboardService.setAAContextData('Current State', update);
          this.setState({
            channel: channel,
            dataPoint: data
          })
        }
        else {
          const {consumerAppLogsCount, history, azureCompatibility,
              isLatestFirmwareVersion} = data,
              {details} = history;
          this.setState({
            channel: channel,
            consumerAppLogsCount,
            isMobileLogsAvailable: _.some(details, item => {
              return item.consumerAppLog;
            })
          });
        }
      }
    }));

const Info = {
  id: "appliance-dashboard:applianceInfo",
  name: "Appliance Info",
  type: Widget,
  description: "Show Appliance Info",
  icon: "",
  tags: "appliance info"
};

export default {
  Info,
  Widget
};
