import View from "./View.jsx";

export default [
  {
    path: "/about",
    controller: context => {
      return {
        component: View,
        context
      };
    }
  }
];
