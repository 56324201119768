import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import Config from "../../config";
import ApplianceTypes from "./appliance-types";
import AnswerAdvisorFooter from "../answer-adviser/AnswerAdviserFooter.jsx";
import ApplianceService from "./Service";
import AI from "../../appInsights"
import AnswerAdviser from "../../modules/answer-adviser";
import util from "../../components/util";
import DashboardService from '../dashboard/Service';

const titleMapping = {
  "ONLINE": "Connected Online",
  "OFFLINE": "Connected Offline",
  "READY": "Connected Ready",
  "CAPABLE": "Connected Capable"
};

export default ReactClass({
  displayName: "DeviceList",
  propTypes: {
    appliances: PropTypes.array,
    onApplianceSelected: PropTypes.func.isRequired,
    selectedDeviceId: PropTypes.string,
    selectedSerial: PropTypes.string
  },

  getInitialState() {
    return {
      applListSidePanel: false,
      showInput: false,
      serialNo: "",
      isLoading: false,
      isLoadingMoreAppliances:false,
      hasMoreDevices: true,//initially enabled, if no devices it will be disabled  
      appliances:this.props.appliances || [],
      deviceUserIds:[],
    };
  },
  componentDidUpdate(prevProps) {
    // Check if the appliances prop has changed
    if (prevProps.appliances !== this.props.appliances) {
      // Update the state with the new appliances
      this.setState({
        appliances: this.props.appliances
      });
    }
  },

  getAppliances(){
    return this.state.appliances;
  },


  selectAppliance(selectedIndex, selectedDevice = "", selectedAppliances = []) {
        let appliances;
        if(selectedDevice === "NewDevice") {
          appliances = selectedAppliances;
        } else {
          appliances = this.getAppliances();
        }
        const selectedDeviceId = appliances[selectedIndex].deviceId,
        selectedSerial = appliances[selectedIndex].serial;
        const userSessoinId = util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
            applianceSessionID = sessionStorage.getItem("applianceSessionID");
        let param = AnswerAdviser.getCRMTicket();
        const {CRM_TICKET_ID} = param;
        const applianceSessionEventData = {model: appliances[selectedIndex].model,
          serial: appliances[selectedIndex].serial, softwareVersions: JSON.stringify(appliances[selectedIndex].versions), 
          pumaVersion: appliances[selectedIndex].pumaVersion,
          userSessoinId, applianceSessionID,
          CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : ""};
        if(!appliances[selectedIndex].pumaVersion){
          DashboardService.fetchGroupDetails(appliances[selectedIndex]).then((groupDetails)=>{
            this.sendApplianceSessionEvent({
              ...applianceSessionEventData,
              firmwareVersion: groupDetails.FirmwareVersion
            });
          })
        }
        else{
          this.sendApplianceSessionEvent(applianceSessionEventData);
        }
    ApplianceService.setScrollTopOfApplianceList(this.applianceList.scrollTop),
    this.props.onApplianceSelected(selectedDeviceId, selectedSerial);
  },

  sendApplianceSessionEvent(data){
    AI.trackUserLoginEvent("APPLIANCE_SESSION", data);
  },

  renderApplianceRows() {
    const {selectedDeviceId, selectedSerial} = this.props;
    const appliances = this.getAppliances();
    let selectedIdx = "";
    if(selectedDeviceId)
      selectedIdx = appliances.map(e => e.deviceId).indexOf(selectedDeviceId);
    else
      selectedIdx = appliances.map(e => e.serial).indexOf(selectedSerial);

    if(selectedIdx == -1)
      selectedIdx = 0;
    return appliances.map((a, i) => {
      const {model: label = "unknown", connectedStatus = ""} = a;

      return (
        <li key={a.deviceId + i}
            className={selectedIdx == i ? "selected": ""}
            onClick={()=>{
              if(a.deviceId!=selectedDeviceId){
                ApplianceService.setShowMoreDevicesButton(false);
              }
              this.selectAppliance(i)
            }}>
          <img src={`${Config.baseDir}images/${ApplianceTypes.getType(a.type)}_icon.svg`}
              alt="Icon" />
          <span title={`Serial: ${a.serial ? a.serial : "N/A"}`}>{a.model ? label : "N/A"}</span>
          {(!connectedStatus || connectedStatus === "UNAVAILABLE"
              ? null
              : <i className={`icon icon-wifi_icon conn-status ${connectedStatus}`} title={titleMapping[connectedStatus]}></i>)}
        </li>
      );
    });
  },
  storeSerialNo(event) {
    this.setState({
      serialNo: event.target.value
    });
  },

  setNewAppliance() {
    const {application: app} = this.props,
    {notifications} = app;
    this.setState({
      isLoading: true
    })
    let retVal = {
      serialNo: [this.state.serialNo]
    };
    let Appliances = ApplianceService.getCurrentApplianceInfos();
    let serialArray = [];
    Appliances.map((value) => {
      serialArray.push(value.serial);
    })
    if(!serialArray.includes(this.state.serialNo)) {
      let applianceInfoPromise = ApplianceService.getApplianceInfo(retVal, true);
      applianceInfoPromise.then(ApplianceService.modifyAppliances)
      .then((res) => {
        const selectedAppliances = [... Appliances, ...res];
        ApplianceService.setCurrentApplianceInfos(selectedAppliances);
        ApplianceService.setShowMoreDevicesButton(false);
        this.selectAppliance(selectedAppliances.length - 1, "NewDevice", selectedAppliances)
      }).catch(errRes => {
        notifications.error(`Serial Number : ${this.state.serialNo} is not compatible with SubZero Connected Portal`);
        this.setState({
          isLoading: false
        })
      });
    } else {  
        setTimeout(() => {
            this.setState({
                isLoading: false
              })
            notifications.error(`Device with Serial Number ${this.state.serialNo} is already added`);
          }, 1000);
        }
  },
  getNextDeviceUserIds(){
    if(this.state.deviceUserIds.length==0){
      return [];
    }
    return this.state.deviceUserIds.slice(0, 2);
  },
  showMoreAppliancesOfUser(){
    this.setState({
      isLoadingMoreAppliances:true
    });
    const {application: app} = this.props,
    {notifications} = app;
    let Appliances = this.state.appliances;
    if(Appliances && Appliances.length>0) {
      const userId = Appliances[0].uniqueUserId;
      const deviceId = Appliances[0].deviceId;
      const isMultiUserDevice = Appliances[0].multiUserDevice;
      const userIds = isMultiUserDevice?this.getNextDeviceUserIds(): (userId?[userId]:[])
      console.log("Next IDs--->", userIds);
      console.log("Remaining IDs--->", this.state.deviceUserIds);
      ApplianceService.getAppliancesOfUser(userIds)
      .then((res) => {
        const filteredDevices = res.filter(device => device.deviceId !== deviceId);
        const selectedAppliances = [...Appliances, ...filteredDevices.filter(device => !Appliances.some(appliance => appliance.deviceId === device.deviceId))];
        ApplianceService.setCurrentApplianceInfos(selectedAppliances);
        this.selectAppliance(0, "NewDevice", selectedAppliances)
        const wasAlreadyInList = (filteredDevices!= null && filteredDevices.length==0); 
        const hasMoreDevices = (filteredDevices!= null && filteredDevices.length>0)
        || this.state.deviceUserIds?.length>0
        setTimeout(()=>{
          this.setState({
            isLoading: false,
            isLoadingMoreAppliances:false,  
            hasMoreDevices: hasMoreDevices,  
            appliances:selectedAppliances,
            deviceUserIds:this.state.deviceUserIds.filter(record => !userIds.includes(record))
          })
        }, 1000)
        if(wasAlreadyInList){
          notifications.info("Fetched devices are already added in above list.")
        }
        if(!hasMoreDevices){
          notifications.error("No other devices linked to this user")
        }

      }).catch(errRes => {
        console.error("showMoreAppliancesOfUser--->", errRes);
        notifications.error("Something went wrong while fetching devices linked to user. Please try again later.");
        this.setState({
          isLoading: false,
          isShowMoreDeviceButtonEnabled: true,
          isLoadingMoreAppliances:false
        })
      });
    } 
  },

  render() {
    const appliances = this.renderApplianceRows(),
        content = appliances.length ? appliances : <li className="empty">No Appliances</li>;
        const { showInput, serialNo, isLoading } = this.state;
      
    let isShowMoreDeviceVisible = false;
    if(this.state.appliances && this.state.appliances.length>0){
      const firstAppliance = this.state.appliances[0]; 
      isShowMoreDeviceVisible = firstAppliance.multiUserDevice? this.state.deviceUserIds.length>0: firstAppliance.uniqueUserId!=null && this.state.appliances.length==1;
    }
    let forceHideShowMoreDeviceForUser = false;
    if(window?.location?.href?.includes("/graph/select-data") || window?.location?.href?.includes("/Connected/graph")){
      forceHideShowMoreDeviceForUser = true
    }
    return (
      <div className="appliance-list-container">
        <ul ref={x => this.applianceList = x}
          className="list appliance-list">
          {content}
          
          {
            ApplianceService.shouldShowMoreDevicesButton() && isShowMoreDeviceVisible && !forceHideShowMoreDeviceForUser &&
            <li>
            <button onClick={() => {
              this.showMoreAppliancesOfUser();
            }} style={{ backgroundColor: "#53565A", marginBottom: "0px", color:"#fcfcfc", "textTransform": "unset"}} 
              disabled={(this.state.isLoadingMoreAppliances || this.state.isLoading) || !this.state.hasMoreDevices}>
              {
                 this.state.isLoadingMoreAppliances ? <i className="anim icon icon-loader spin"></i> : "Show more units for this user" 
              }
            </button>
          </li>
          }

          {showInput &&
            <li
              style={{
                height: "46px",
                overflow: "hidden"
              }}
            >
              <input ref="serialNoInput" values={serialNo}
                onChange={this.storeSerialNo}
                placeholder="Serial Number"
                style={{
                  height: "36px",
                }}
                disabled={this.state.isLoadingMoreAppliances}
              />
              <button style={{
                marginLeft: "2%",
                width: "17%",
                height: "36px"
              }}
              onClick={() => {
                this.setNewAppliance();
              }}
              disabled={(!serialNo ? true : isLoading) || this.state.isLoadingMoreAppliances}
              >{!isLoading ? <img src={`${Config.baseDir}images/tick_icon.svg`} alt="Icon" /> : <i className="anim icon icon-loader spin"></i> }</button>
            </li>
          }

          {!showInput &&
            <li>
              <button onClick={() => {
                this.setState({
                  showInput: !showInput
                })
              }} style={{ backgroundColor: "#53565A", marginBottom: "0px"}}      
              disabled={this.state.isLoadingMoreAppliances}><img src={`${Config.baseDir}images/plus_icon.svg`}
              alt="Icon" style={{
                width: "10px"
              }} /></button>
            </li>
          }
        </ul>
        <AnswerAdvisorFooter />
      </div>
    );
  },

  fetchDeviceUserIds(props){
    const {appliances, selectedDeviceId} = props;
    if(appliances && appliances.length>0 && appliances[0].multiUserDevice){
      ApplianceService.getDeviceUserIds(selectedDeviceId)
      .then((res)=>{
        console.log("getDeviceUserIds-->", res);
        this.setState({
          deviceUserIds: res
        })
      })
      .catch(e=>{
        console.error("Unable to fetch user ids of device", e)
      })
    }
  },

  componentDidMount() {
    const {appliances = this.state.appliances, selectedDeviceId} = this.props,
        scrollTop = ApplianceService.getScrollTopOfApplianceList(),
        selectedIdx = appliances.map(e => e.deviceId).indexOf(selectedDeviceId),
        clientHeightOfFirstNode = this.applianceList.children[0].clientHeight;

    this.applianceList.scrollTop = scrollTop || (selectedIdx * clientHeightOfFirstNode);
    this.fetchDeviceUserIds(this.props);
  },
  componentWillReceiveProps (nextProps) {
    const {appliances: oldAppliances, selectedDeviceId:oldSelectedDeviceId } = this.props;
    const {appliances: newAppliances, selectedDeviceId:newSelectedDeviceId } = nextProps;
    if(oldAppliances?.length!=newAppliances?.length || newSelectedDeviceId!=oldSelectedDeviceId){
      console.log('Fetching device user ids')
      this.fetchDeviceUserIds(nextProps);
    }
  }
});
