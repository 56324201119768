	/* global console setTimeout */
  import React from "react";
  import ReactClass from "create-react-class";
  
  import * as _ from "lodash";
  // import AmCharts from "@amcharts/amcharts3-react";
  
  import GraphLegend from "./GraphLegend.jsx";
  import AI from "../../appInsights";
  import * as AmC5Components from "./AmChart5GraphComponent";
  
  export default ReactClass({
    displayName: "GraphComponent",
    id: null,
    root: null,
    stockChart:null,
    isChartLoaded:false,
    loadedPanelCount:0,
    amChart5GraphComponent: new AmC5Components.AmChart5GraphComponent(),//how to create AMChart component object
    cachedGraphState:{
      loadedProperties: new Set(),
    },
  
  
    getInitialState(){
      return {
        loading: true,
        loadingMessage: null
      }
    },
    componentWillMount() {
      this.id = "maingraph";
    },
  
    componentWillReceiveProps(nextProps) {
      const {data: data, config:config, propModel: propModel, selectedGraphPanel, timeSelection} = this.props;
      const {data: newData, config:newConfig, propModel: newPropModel, selectedGraphPanel: newSelectedGraphPanel, timeSelection: newTimeSelection} = nextProps;
      if (propModel != newPropModel || newConfig != config || selectedGraphPanel != newSelectedGraphPanel || timeSelection != newTimeSelection || data?.meta?.request != newData?.meta?.request) {
        if(selectedGraphPanel == newSelectedGraphPanel && (this.setPropertyFieldRemoved(config, newConfig) && timeSelection == newTimeSelection && data?.meta?.request == newData?.meta?.request)){//handled don't plot graph if there is no change in the panels
          return;
        }
        this.showGraphPlotting(true, "Plotting Graph. Please wait...");
        console.log("Initializing Graph-", this.id, "with data:", nextProps, "and props:", this.props);
        this.cachedGraphState.loadedProperties.clear();
        setTimeout(() => this.initializeGraph(nextProps), 1000);
      }
    },
   
    setPropertyFieldRemoved(oldConfig, newConfig){
      if(!this.stockChart){
        return false;
      }
      const oldDataSet = oldConfig?.dataSets?.length>0 ? oldConfig.dataSets[0] : []; 
      const newDataSet = newConfig?.dataSets?.length>0 ? newConfig.dataSets[0] : []; 
      const oldSelectedProperties = oldDataSet.fieldMappings?.map(mapping => mapping.prop).filter(Boolean);
      const newSelectedProperties = newDataSet.fieldMappings?.map(mapping => mapping.prop).filter(Boolean);
  
      const removedProperties = oldSelectedProperties?.filter(prop => !newSelectedProperties?.some(newProp => newProp.name === prop.name)) || [];
      const addedProperties = newSelectedProperties?.filter(prop => !oldSelectedProperties?.some(oldProp => oldProp.name === prop.name)) || [];

      if(removedProperties.length > 0 || addedProperties.length > 0){
        console.log("Properties are not equal");
        // Update stock chart accordingly
        removedProperties.forEach(prop => {
          this.stockChart.panels.each(panel => {
            panel.series.each(series => {
              if(series.get("propName") === prop.name) {
                series.hide();
              }
            });
          });
        });
        const displayedProps = [];
        const newProps = []
        addedProperties.forEach(prop => {
          //If Prop was hidden then show it
          if(this.cachedGraphState.loadedProperties.has(prop.name)){
            this.stockChart.panels.each(panel => {
              panel.series.each(series => {
                if(series.get("propName") === prop.name) {
                  series.setAll({ 
                    fill: am5.color(prop.color),
                    stroke: am5.color(prop.color),
                  });
                  series.show();
                  displayedProps.push(prop.name);
                }
              });
            });
          } 
          else{
            newProps.push(prop);
          }
        });
        
        if(newProps.length > 0){
          return false;
        } 
      }
      return true;
    },
  
    componentWillUnmount() {
      if (this.root) {
        this.root.dispose();
      }
    },
  
    async initializeGraph(props) {
      if (this.root) {
        this.root.dispose(); // Clean up existing root
        this.root = null;
        this.stockChart = null;
      }
      const {config, data, setHoverRecord} = props;
      if(!data){
        return;
      }
      const dataSet = config?.dataSets?.length>0 ? config.dataSets[0] : [];
      const selectedProperties = dataSet.fieldMappings?.map(mapping => mapping.prop).filter(Boolean);
      const graphPanels = config.panels;
  
      //setup graph root
      const root = this.amChart5GraphComponent.createGraphRoot(this.id);
      //Setup stock chart
      const stockChart = this.amChart5GraphComponent.getStockChart(root);
      this.stockChart = stockChart;
      // const dateAxis = this.amChart5GraphComponent.getDateAxis(root);
      const convertedRecords = this.preProcessData(data);
      const {graphPanelObjs, lastVisiblePanelIndex, dateAxis} = this.buildGraphPanels(root, stockChart, graphPanels);
      this.buildValueAxes(root, graphPanels, graphPanelObjs, selectedProperties);
      this.buildSeries(root, graphPanels, graphPanelObjs, selectedProperties, data);
      this.renderFinalGraph(root, stockChart, graphPanelObjs, convertedRecords);
  
      this.setupScrollbar(root, graphPanelObjs, dateAxis, lastVisiblePanelIndex, convertedRecords);
      this.root = root;
      this.listenForGraphStatus(stockChart, dateAxis); 
  
    },
  
    setupStockChartCursorLock(stockChart, root) {
        const cursors = [];
        let isLocked = false;
        let lockedPosition = null;

        // Setup cursors for all panels
        stockChart.panels.each((panel) => {
            // Create cursor
            const cursor = am5xy.XYCursor.new(root, {
                behavior: "none"
            });
            panel.set("cursor", cursor);
            cursors.push(cursor);

            // // Handle cursor movement for each panel
            cursor.events.on("cursormoved", (ev) => {
                lockedPosition = ev.target.getPrivate("positionX");
            });
        });
        const unlockCursor = () => {
          isLocked = false;
          lockedPosition = null;
          
          cursors.forEach(cursor => {
              cursor.setAll({
                alwaysShow: false,
                positionX: undefined
              });
              cursor.lineX.setAll({
                strokeWidth: 1,
                strokeDasharray: [1,1],
                stroke: am5.color("#464648")
              });
          });

          // Show all tooltips
          stockChart.panels.each((p, index) => {
            if(index==0){
              this.hideLockIndicator(p);
            }
          });
          this.resetHoverLock();
        }

        const lockCursor = () => {
          isLocked = true;  
          cursors.forEach(cursor => {
            cursor.setAll({
              positionX: lockedPosition,
              alwaysShow: true
            });
            cursor.lineX.setAll({
              strokeWidth: 4,
              strokeDasharray: [],
              stroke: am5.color("#464648")
            });
          });
          // Hide all tooltips
          stockChart.panels.each((p, index) => {
            if(index==0){
              this.showLockIndicator(p, root, unlockCursor);
            }
          });
          this.setHoverLock();
        }

        
        // Handle click on any panel
        stockChart.panels.each((panel) => {
            panel.plotContainer.events.on("click", (ev) => {
                if (!isLocked && lockedPosition) {// Lock all cursors
                  lockCursor();
                } 
                else {// Unlock all cursors, toggle lock
                  unlockCursor();
                }
            });
        });
    },
  
    // Optional: Visual lock indicator
   showLockIndicator(panel, root, unlockCursor) {
      if (!panel.lockIndicator) {
        panel.lockIndicator  = panel.plotContainer.children.push(
              am5.Label.new(root, {
                x: am5.percent(99),
                y: -48,
                html: `<div title="Click to unlock cursor" style="font-size: 20px; cursor: pointer;">🔒</div>`,
                fontSize: 14,
                centerX: am5.percent(50),
                centerY: am5.percent(50),
                cursorOverStyle: "pointer",
                background: am5.Rectangle.new(root, {
                  fill: am5.color("#464648"),
                  fillOpacity: 1
                }),
              })
          );
          panel.lockIndicator.events.on("click", unlockCursor);
      }
      panel.lockIndicator.show();
    },
  
    hideLockIndicator(panel) {
      if (panel.lockIndicator) {
        panel.lockIndicator.dispose();
        panel.lockIndicator = null;
      }
  },
  
    preProcessData(data){
      const convertedRecords = data.records.map(record => ({
        ...record,
        ts: new Date(record.ts).valueOf()
      })).sort((a, b) => a.ts - b.ts);
      return convertedRecords;
    },
  
    buildGraphPanels(root, stockChart, graphPanels){
      const graphPanelObjs = {};
      let lastVisiblePanelIndex = -1;
      const dateAxis = this.amChart5GraphComponent.getDateAxis(root);
      graphPanels.forEach((panel, index) => {
        if (panel.percentHeight===0) {
          return;
        }
        const panelObj = this.amChart5GraphComponent.getStockPanel(root, panel);
        graphPanelObjs[index.toString()] = {
          amChartStockPanel: panelObj,
          amChartDateAxis: dateAxis,
          amChartValueAxes: {},//filles as we go through properties
          amSerieses: []
        };
        panelObj.xAxes.push(dateAxis);
        stockChart.panels.push(panelObj);
        lastVisiblePanelIndex = index;
      });
      return {graphPanelObjs, lastVisiblePanelIndex, dateAxis};
    },
  
    buildValueAxes(root, graphPanels, graphPanelObjs, selectedProperties){
      selectedProperties?.forEach((prop, index) => {
        const panelIndex = prop.panelIdx || 0;
        if (graphPanels[panelIndex].percentHeight === 0) {
          return;
        }
        const panelEntry = graphPanelObjs[panelIndex.toString()];
        const panelObj = panelEntry.amChartStockPanel;
        const dateAxis = panelEntry.amChartDateAxis;
        if (!panelObj) {
          console.warn(`No panel found for property ${prop.name} with index ${panelIndex}`);
          return;
        }
      
        if(!panelEntry.amChartValueAxes[prop.type]){
          const valueAxisTemp = this.amChart5GraphComponent.createValueAxis(root, prop);
          panelEntry.amChartValueAxes[prop.type] = valueAxisTemp;
          panelObj.yAxes.push(valueAxisTemp);
          panelEntry.amChartStockPanel.plotContainer.events.on("wheel", (event) => {
            this.onGraphZoomChange(dateAxis);
          });
    
        }
      });
    },
  
    buildSeries(root, graphPanels, graphPanelObjs, selectedProperties, data){
      selectedProperties?.forEach((prop, index) => {
        const panelIndex = prop.panelIdx || 0;
        if (graphPanels[panelIndex].percentHeight === 0) {
          return;
        }
        const panelEntry = graphPanelObjs[panelIndex.toString()];
        const panelObj = panelEntry.amChartStockPanel;
        const dateAxis = panelEntry.amChartDateAxis;
        if (!panelObj) {
          console.warn(`No panel found for property ${prop.name} with index ${panelIndex}`);
          return;
        }
        let valueAxis, series;
        const keyField = data.meta.keyMap[prop.name];
        
        valueAxis = panelEntry.amChartValueAxes[prop.type];
  
        series = this.amChart5GraphComponent.createSeries(root, prop, panelObj, 
          dateAxis, valueAxis,
          'ts', keyField);
        series.get("tooltip").set("forceHidden", true);
        // panelObj.set("cursor", this.amChart5GraphComponent.getXYCursor(root, series, dateAxis, valueAxis));
        panelEntry.amSerieses.push(series);
        // this.amChart5GraphComponent.setupSeriesTooltip(root, prop, series);
        // this.amChart5GraphComponent.setupSeriesData(root,convertedRecords, series);
        this.cachedGraphState.loadedProperties.add(prop.name);
      });
    },
  
    setupScrollbar(root, graphPanelObjs, dateAxis, lastVisiblePanelIndex, convertedRecords){
       //Setup Horizontal Scrollbar to enable zooming on x-axis
       const scrollbar = this.amChart5GraphComponent.getDateAxisScrollbar(root, convertedRecords)
       scrollbar.events.on("rangechanged", () => {
         this.onGraphZoomChange(dateAxis);
       });
       const lastPanelEntry = graphPanelObjs[lastVisiblePanelIndex.toString()];
       if(lastPanelEntry){
         lastPanelEntry.amChartStockPanel.set("scrollbarX", scrollbar);
         lastPanelEntry.amChartStockPanel.bottomAxesContainer.children.push(scrollbar);
       }
    },
  
    renderFinalGraph(root, stockChart, graphPanelObjs, convertedRecords){
      Object.keys(graphPanelObjs).forEach(panelIndex => {
        const panelEntry = graphPanelObjs[panelIndex];
        const panelObj = panelEntry.amChartStockPanel;
        panelEntry.amSerieses.forEach(series => {
          panelObj.series.push(series);
          this.amChart5GraphComponent.setupSeriesData(root, convertedRecords, series);
        });
      });
      stockChart.panels.each((panel) => {
        panel.panelControls.set("visible", false);
      });
    },
  
    listenForGraphStatus(stockChart, dateAxis) {
      // Track series loading for each panel
      let loadedPanels = 0;
      const totalPanels = stockChart.panels.length;
  
      // Listen to series data validation
      stockChart.panels.each((panel) => {
          let seriesLoaded = 0;
          const totalSeries = panel.series.length;
  
          panel.series.each((series) => {
              series.events.on("datavalidated", () => {
                  console.log(`Series loaded in panel ${panel.uid}`);
                  seriesLoaded++;
  
                  if (seriesLoaded === totalSeries) {
                      console.log(`All series loaded in panel ${panel.uid}`);
                      loadedPanels++;
  
                      if (loadedPanels === totalPanels) {
                          console.log("All panels fully loaded");
                          const start = dateAxis.get("start", 0);
                          const end = dateAxis.get("end", 1);
                          const startDate = dateAxis.positionToDate(start);
                          const endDate = dateAxis.positionToDate(end);
                          this.notifyGraphZoom('rendered', startDate, endDate);
                            
                          this.setupStockChartCursorLock(this.stockChart, this.root);
                          this.amChart5GraphComponent.setupCursorEvents(this.stockChart, this.props.setHoverRecord, false);
                          this.showGraphPlotting(false);
                          panel.plotContainer.events.on("wheel", (event) => {
                            this.onGraphZoomChange(dateAxis);
                          });
                      }
                  }
              });
          });
      });
  
      // Safety timeout if series drawing takes longer than 2 minutes
      setTimeout(() => {
        if (this.state.isLoading) {
          console.warn("Loading timeout reached");
          this.showGraphPlotting(false, "Unable to plot some or all properties. Refresh the page to try again.");
        }
      }, 2*60*1000);//2 minutes timeout 
    },
  
    onGraphZoomChange(dateAxis){
      const start = dateAxis.get("start");
      const end = dateAxis.get("end");
      console.log("Scrollbar :", start,' // ', end);
      const startDate = dateAxis.positionToDate(start);
      const endDate = dateAxis.positionToDate(end);
      this.notifyGraphZoom('zoomed', startDate, endDate)
    },
  
    notifyGraphZoom(type, startTime, endTime){
      const {config:{listeners}} = this.props;
      if(listeners && listeners.length==2){
        const callEvent = type==='zoomed'? listeners[0]: listeners[1];
        console.log("CallEvent--->", callEvent);
        callEvent.method({
          type:type,
          startDate: startTime,
          endDate: endTime
        })
      }
    },
  
    setHoverRecord(hoverRecord) {
      // this.props.onGraphHover(hoverRecord);
      // this.props.setHoverRecord(hoverRecord);
    },
  
    handleFieldToggle(field) {
      const panels = this.chartRef.state.chart.panels;
  
      let targetPanel, targetGraph;
  
      panels.forEach(p => {
        targetGraph = _.find(p.graphs, g => {
          if (g.valueField === field) {
            targetPanel = p;
            return true;
          }
        }) || targetGraph;
      });
  
      if (targetGraph) {
        targetPanel[targetGraph.hidden ? "showGraph" : "hideGraph"](targetGraph);
  
        AI.trackEvent("GraphToggleProp",
            Object.assign({field},
                _.pick(this.props.currentAppliance, "deviceId", "model", "name", "serial", "type")));
      }
    },
  
    handleFieldHover(field) {
      this.stockChart?.panels?.each((panel) => {
        // Loop through each series in the panel
        panel.series.each((series) => {
          // Set opacity based on whether the valueField matches the one to highlight
          let opacity = (series.get("valueYField") === field) ? 1 : 0.3;
          if(!field){
            opacity = 1;
          }
          series.setAll({ opacity });
          // series.strokes.template.setAll({ opacity });
        });
      });
    },
  
    handleFieldMove(moveMeta) {
      this.props.onFieldShuffle(moveMeta);
      this.props.setLegendDragFrom(-1);
    },
  
    setTimeTo({startTime, endTime}) {
      if (startTime && endTime) {
        this.chartRef.state.chart.zoom(new Date(startTime), new Date(endTime));
      }
    },
  
    setHoverLock() {
      this.props.setLockedHoverRecord(this.props.hoverRecord);
    },
  
    resetHoverLock() {
      this.props.setLockedHoverRecord(null);
    },
  
    getMinMaxText(index){
      const panelClassName = "amChartsPanel amcharts-stock-panel-div amcharts-stock-panel-div-stockPanel"+index;  
      const collection = document.getElementsByClassName(panelClassName);
      if(collection && collection.length>0){
        const panelDom = collection[0];
        if(panelDom.style.height=="100%"){
          return "Minimize";
        }
        else{
          return "Maximize";
        }
      }
    },
  
    showGraphPlotting(isPlotting, message){
      setTimeout(() => {
        console.log("isPlotting--->", isPlotting);
        this.setState({loading: isPlotting, loadingMessage: message });
      }, 50);
    },
  
    render() {
      const defaultPanelHeight = 50;
      const data = this.props.data,
          {propModel, hoverRecord} = this.props,
          panels = propModel && propModel.panels || {sizes: [defaultPanelHeight, defaultPanelHeight], titles: ["", ""]}
          ;
      // console.log("Before Testing:", propModel, "configs:",this.props.config);
      let config = this.props.config;
      config.panels = config.panels.map((p, index) =>{ 
        const panelDom = this.props.getGraphPanelDomByPanelTitle(panels.titles[index]);
        if(panelDom){
          panelDom.style.height = panels.sizes[index]+'%';
        };
        return Object.assign(p, {
        percentHeight: panels.sizes[index],
        title: panels.titles[index]
      })});
      // console.log("After Testing:", propModel, "configs:",this.props.config);
      return data && data.records && propModel ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div className="graph">
            {/* <AmCharts.React ref={x => {
                this.chartRef = x
                this.props.setChartRef(x);
              }}
              className="graph-component"
              options={config} /> */}
            <div className="graph-wrap">
              <div id={this.id} style={{ width: "100%", height: "100%" }}></div>
              <div id={`${this.id}-controls`}
                style={{ height: "auto", width: '100%', padding: "5px" }}
              ></div>
            </div>
            {panels.sizes.map((height, index) => (
              <GraphLegend key={index} index={index}
                          top={index === 0 ? 0 : panels.sizes[index-1]}
                          keyMap={this.props.keyMap} config={config}
                          record={hoverRecord}
                          onFieldMoveStart={index => this.props.setLegendDragFrom(index)}
                          onFieldMoveCancel={() => this.props.setLegendDragFrom(-1)}
                          onFieldMoved={this.handleFieldMove}
                          droppable={[-1, index].indexOf(this.props.legendDragFrom) === -1}
                          onFieldRemove={field => this.props.onFieldRemove(field)}
                          onFieldToggle={this.handleFieldToggle}
                          onFieldHover={this.handleFieldHover}
                          selectedGraphPanel={this.props.selectedGraphPanel}
              />
            ))}
            {this.state.loading && (
              <div className="busy-indicator" style={{ width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.30)", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, left: 0, zIndex: 9999 }}>{this.state.loadingMessage}</div>
            )}  
            {/* {this.props.selectedGraphPanel==-1 && panels.titles.map((title, index) => (
              <div key={index} className="panel-container"
                  style={{top: ((97  / panels.titles.length) * index) + '%'}}>
                    <div className="panel-title">{title}</div>
              </div>
            ))} */}
          </div>
        </div>
      ) : (<div className="busy-indicator">{this.props.busyMessage || "Loading..."}</div>);
    }
  });
  //{this.getMinMaxText(index)}</button>   	