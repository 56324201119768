/* console Promise */
import View from "./View.jsx";
import GraphFilter from "./GraphFilter.jsx"

export default [
  {
    path: "/appliance/:deviceId/non-connected/:applianceType?/graph/:logType",
    controller: context => {
      const {logType} = context.route.params;
      return {
        component: View,
        context: Object.assign({connected: false, mode: logType}, context)
      };
    }
  },
  {
    path: "/appliance/serial/:serial/non-connected/:applianceType?/graph/:logType",
    controller: context => {
      const {logType} = context.route.params;
      return {
        component: View,
        context: Object.assign({connected: false, mode: logType}, context)
      };
    }
  },
  {
    path: "/appliance/:deviceId/graph/select-data/:uniqueId",
    controller: context => {
      return {
        component: GraphFilter,
        context
      };
    }
  },
  {
    path: "/appliance/:deviceId/connected/graph",
    controller: context => {
      return {
        component: View,
        context: Object.assign({connected: true, mode: "Connected"}, context)
      };
    }
  }
];
