/* global console setTimeout Promise */
import React from "react";
import ReactClass from "create-react-class";
import * as _ from "lodash";

import ScalePropertySelector from "./PropertySelector.Scale.jsx";
import EnumPropertySelector from "./PropertySelector.Enum.jsx";
import EventPropertySelector from "./PropertySelector.Event.jsx";

import PropertyModel from "./PropertyModel";
import DialogService from "../../components/widgets/DialogService";
import AI from "../../appInsights";

const SelectorContainer = ReactClass({
  displayName: "PropertySelector",
  getInitialState() {
    return {
    };
  },
  render() {
    const {prop} = this.props;
    return (
        <section className="property-select-customise">
          <header>
            {prop.group} - {prop.displayName}
          </header>
          <section>{this.props.children}</section>
          <footer>
            <button className="inline primary header-action dropdown-dialog-button"
                    onClick={this.props.onClose}>Close</button>
            <button className="inline primary header-action"
                    onClick={() => this.props.onAdd(prop)}>Add Attribute(s)</button>
          </footer>
        </section>
    );
  }
});


export default ReactClass({
  displayName: "PropertySelector",
  getInitialState() {
    return {
      panelShowing: false,
      originalPropGroups: [],
      propGroups: [],
      properties: [],
      filterText: "",
      showProperties: undefined
    };
  },
  componentWillReceiveProps(nextProps) {
    const {type, mode, showProperties} = nextProps;

    if (type && mode) {
      PropertyModel.forModel(type, mode).then(propModel => {
        this.setState({propModel});
        if (type && type !== this.state.applianceType) {
          this.resetProperties(type, mode);
        } else if (showProperties && showProperties.length && !this.state.showProperties) {
          this.setState({showProperties});
          this.resetProperties(type, mode, showProperties);
        }
      }, errResponse => {
      });
    }

    if (nextProps.fieldSelection) {
      this.setState({fieldSelection: nextProps.fieldSelection});
      let props = _.cloneDeep(this.state.properties);
      nextProps.fieldSelection.forEach(prop => {
        const index = _.findIndex(props, {id: prop.id});
        if (index !== -1) {
          props = [
            ...props.slice(0, index),
            Object.assign({}, props[index], {selected: true}),
            ...props.slice(index+1)
          ];
        }
      });
      this.setState({
        properties: props
      });
    }
  },

  resetProperties(type, mode, showProperties = undefined) {
    const selections = this.state.fieldSelection;
    PropertyModel.forModel(type, mode).then(propModel => {
      if (!propModel) {
        return;
      }

      this.propertyModel = new PropertyModel(propModel);
      if (showProperties && showProperties.length) {
        this.propertyModel.keep(showProperties);
      }

      const propGroups = _.sortBy(
          this.propertyModel.byTypeGrouped("Temp", "RPM", "Numeric", "Number", "Bool", "Enum"),
          "group"
      );

      this.setState({
        properties: _.clone(this.propertyModel.getAll()).map(p => {
          p.selected = !!_.find(selections, {id: p.id});
          return p;
        }),
        originalPropGroups: propGroups,
        propGroups,
        applianceType: type
      });
    }, errResponse => {
    });
  },

  addProperty(propId, selected, config = {}) {
    const props = this.state.properties,
        index = _.findIndex(props, {id: propId});
    if (index !== -1) {
      this.setState({
        properties: [
          ...props.slice(0, index),
          Object.assign({}, props[index], {selected}, config),
          ...props.slice(index+1)
        ]
      });
      return true;
    }
  },

  toggleProperty(propId, selected, config = {}) {
    if (this.addProperty(propId, selected, config)) {
      setTimeout(() => {
        this.trackAddedProperties();
        this.trigger();
      }, 100);
    }
  },

  addProperties(selection) {
    const relatedSelIds = _.keys(selection.relatedSelections).map(Number),
        mainSelId = selection.prop.id,
        toAdd = [mainSelId, ...relatedSelIds];

    let props = _.cloneDeep(this.state.properties);
    toAdd.forEach(propId => {
      const index = _.findIndex(props, {id: propId});
      if (index !== -1) {
        let self = props[index],
            cfg = (selection.panelIdx) ? {panelIdx: selection.panelIdx} : {};

        if (selection.plotLocationSelection) {
          cfg.targetLine = Number(selection.plotLocationSelection);
        } else if (_.includes(relatedSelIds, propId) &&
            selection.relatedSelections[propId].mode === "this-line") {
          cfg.targetLine = mainSelId;
        } else if (_.includes(relatedSelIds, propId) &&
            selection.relatedSelections[propId].mode === "x-axis") {
          cfg.targetLine = "x-axis";
        } else if (_.includes(relatedSelIds, propId) &&
            selection.relatedSelections[propId].mode === "set-point") {
          cfg.against = mainSelId;
        }

        props = [
          ...props.slice(0, index),
          Object.assign({}, self, {selected: true}, cfg),
          ...props.slice(index + 1)
        ];
      }
    });

    this.setState({
      properties: props
    });
    setTimeout(() => {
      this.trackAddedProperties();
      this.trigger();
    }, 100);
  },

  trackAddedProperties() {
    const selected = _.filter(this.state.properties, {selected: true}),
        trackSelected = selected.map(data =>
            JSON.stringify(_.pick(data, "displayName", "group", "name", "type"))
        );
    AI.trackEvent("GraphDataFilter", {
      "Appliance Type": this.state.applianceType, "selectedData": trackSelected.join("/")
    }, {"selectedDataCount": trackSelected.length});
  },

  trigger() {
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(_.filter(this.state.properties, {selected: true}));
    }
  },

  clearAll() {
    const props = this.state.properties;
    this.setState({
      activeProperty: null,
      properties: props.map(p => Object.assign({}, p, {selected: false}))
    });
    setTimeout(() => {
      const selected = _.filter(this.state.properties, {selected: true});
      this.props.onSelectionChange && this.props.onSelectionChange(selected);
    }, 100);
  },

  togglePanel() {
    const newState = !this.state.panelShowing;
    if(newState) {
      AI.trackEvent("GraphDataFilterPanel", {"Appliance Type": this.state.applianceType});
    }
    this.setState({activeProperty: null, panelShowing: newState});

    if (newState === true) {
      DialogService.makeDialog(this.panel, this.button, () => {
        this.setState({panelShowing: false, activeProperty: false});
      });
    }
  },

  filterProps(filterStr) {
    const origProps = this.state.originalPropGroups,
        filtered = () => {
          return _.cloneDeep(origProps).map(group => {
            group.properties = group.properties.filter(prop =>
              prop.displayName.toLowerCase().indexOf(filterStr.toLowerCase()) !== -1
            );
            return group;
          });
        }
    ;
    this.setState({
      filterText: filterStr,
      propGroups: filterStr.trim().length > 1 ?
        filtered().filter(g => g.properties.length > 0) :
        origProps
    });
  },

  expand(activeProperty) {
    this.setState({activeProperty});
  },

  renderFlatList(list) {
    const activeProp = this.state.activeProperty || {};
    return list.map((prop, index) => {
      const checked = prop.id && _.find(this.state.properties, {id: prop.id}).selected,
          preFetch = !!prop.preFetch,
          active = prop.id === activeProp.id,
          hasMore = _.includes(["Enum", "Bool"], prop.type) ||
              (prop.relatedAttributes && prop.relatedAttributes.length),
          itemCls = [(checked ? "selected" : ""), (active ? "active" : "")].join(" ");
      return prop.heading ?
        (<li key={"h_" + index} className="heading"><strong>{prop.heading || "<Unknown>"}</strong></li>) :
        (<li key={"p_" + index} className={itemCls}>
          <i className={"icon-check " + (checked ? "checked" : "unchecked")}
             onClick={() => this.toggleProperty(prop.id, !checked)} />
          <span className={preFetch ? "prefetched" : ""}
                onClick={() => this.toggleProperty(prop.id, !checked)}>{prop.displayName}</span>
          {active ?
              (<label><i className="icon-chevron-right"/></label>) :
              (hasMore ? <label onClick={() => this.expand(prop)}>...</label> : null)
          }
        </li>)
      ;
    });
  },

  renderSelector(prop, propModel) {
    const close = () => this.setState({activeProperty: false}),
        add = () => {
          this.addProperties(
            Object.assign({prop}, this.activePropertySelector.getSelections())
          );
          this.togglePanel();
        },
        existingSeries = this.state.fieldSelection;
    switch(prop.type) {
      case "Enum":
      case "Bool":
        return (
          <SelectorContainer prop={prop} onClose={close} onAdd={add}>
            <EnumPropertySelector prop={prop} model={propModel} series={existingSeries}
                                  ref={x => this.activePropertySelector = x}/>
          </SelectorContainer>
        );
      case "Temp":
      case "RPM":
        return (
          <SelectorContainer prop={prop} onClose={close} onAdd={add}>
            <ScalePropertySelector prop={prop} model={propModel} series={existingSeries}
                                   ref={x => this.activePropertySelector = x}/>
          </SelectorContainer>
        );
      // case "Event":
      //   return (
        // <SelectorContainer prop={prop} close={close}>
        // <EventPropertySelector prop={prop} model={propModel} series={existingSeries} />
        // </SelectorContainer>
        // );
      default:
        return undefined;
    }
  },

  render: function() {
    const props = this.state.properties,
        activeProp = this.state.activeProperty,
        groupsList = _.sortBy(this.state.propGroups, ["group"])
          .map(entry => ([
            {heading: entry.group},
            ...entry.properties
          ])),
        flatList = _.flatten(groupsList),
        panelHeight = 380,
        entryDim = {height: 25, width: 250},
        entriesPerColumn = Math.floor(panelHeight / entryDim.height),
        columnCount = Math.ceil(flatList.length / entriesPerColumn),
        panelWidth = columnCount * entryDim.width + (columnCount * 10),
        selectedIdx = activeProp ? _.findIndex(flatList, {id: activeProp.id}) : 0,
        lastHeadingIdx = _.findLastIndex(flatList, e => !!e.heading, selectedIdx),
        displayList = _.slice(flatList, lastHeadingIdx),
        {activeProperty, filterText} = this.state;

    return (
      <div className="dropdown-dialog-container">
        {props && (<button className="inline primary header-action dropdown-dialog-button"
                           ref={x => this.button = x}
                           disabled={this.props.disabled || false}
                           onClick={this.togglePanel}>
          Data Filters&#160;<i className="icon-chevron-down" />
        </button>)}
        <section ref={x => this.panel = x}
            style={{width: (Math.max(panelWidth, 530)) + "px"}}
            className={"dropdown-dialog property-selector-panel " +
              (this.state.panelShowing ? "in" : "")
            }>
          <header>
            <i className="icon-search" />
            <input type="text" placeholder="Quick Search..."
                   disabled={activeProperty}
                  value={filterText}
                  onChange={e => this.filterProps(e.target.value)}
            />
            <i className="icon-x clear-input" onClick={() => this.filterProps("")} />
            <a onClick={this.clearAll}>Clear All</a>
            <a onClick={this.togglePanel}>Close</a>
          </header>
          <div className="property-list">
            <ul className="property-group">
              {this.renderFlatList(displayList)}
            </ul>
            {activeProperty && this.renderSelector(activeProperty, this.propertyModel)}
          </div>
       </section>
      </div>
    );
  }
});
