/* global location setTimeout console */
import React from "react";
import ReactClass from "create-react-class";
import Service from "./Service";
import * as _ from "lodash";
import {MultiValueInput} from "../../components/widgets";
import Config from "../../config";
import Navbar from "./NavBar.jsx";

const configDataType = {
  Columns: "Column",
  Warnings: "Warning",
  ErrorExceptions: "Error Exceptions"
}
const CustomColumns = ReactClass({
  displayName: "CustomColumns",
  getInitialState: function () {
    const {data} = this.props;
    return {
      data: _.cloneDeep(data)
    };
  },
  componentWillReceiveProps(props) {
    const {data} = props;
    this.setState({
      data: _.cloneDeep(data)
    });
  },
  onValueChange(editable, index, field, val) {
    const {data} = this.state;
    this.setState({
      data: Service.valueChange(data, editable, index, field, val)
    }, () => this.onChange());
  },
  removeRow(index) {
    this.setState({
      data: _.filter(this.state.data, (_, i) => i !== index)
    }, () => this.onChange());
  },
  addRow() {
    const {type} = this.props;
    this.setState({
      data: [...this.state.data, ...Service.getEditableFormData(Service[type])]
    }, () => this.onChange());
  },
  onChange() {
    if ((this.props.onUpdate &&
      typeof (this.props.onUpdate) === "function")) {
      this.props.onUpdate(this.state.data);
    }
  },
  renderColumns() {
    const {data} = this.state,
        rowData = data.map((item, index) => {
          const {edit: {UiName: editUiName, JsonPath: editJsonPath}, data: {UiName, JsonPath}} = item;
          return (
            <tr key={`item${UiName}${index}`}>
              <td key={`in${UiName}1`}>
                <DataInput value={UiName}
                  editable={editUiName}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "UiName", val)} />
              </td>
              <td key={`in${JsonPath}2`}>
                <DataInput value={JsonPath}
                  editable={editJsonPath}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "JsonPath", val)} />
              </td>
              <td><i className="icon icon-trash" onClick={this.removeRow.bind(this, index)}></i></td>
            </tr>
          );
        });
    return {
      tHeader: <tr>
        <th>Column Name</th>
        <th>JSON Path in Device Twin</th>
        <th>Action</th>
      </tr>,
      rowData
    };
  },
  renderErrorExceptions() {
    const {data} = this.state,
        rowData = data.map((item, index) => {
          const {edit: {ApplianceType: editApplianceType, ErrorCodes: editErrorCodes}, data: {ApplianceType, ErrorCodes}} = item;
          return (
            <tr key={`item${ApplianceType}${index}`}>
              <td key={`in${ApplianceType}1`} title="Add whole Device Types or Use Regular Expression e.g. 1.2.3.4 or 1.2.*">
                <DataInput value={ApplianceType}
                  editable={editApplianceType}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "ApplianceType", val)} />
              </td>
              <td key={`in${ErrorCodes}2`}>
                <MultiValueInput ref="deviceIdInput" values={ErrorCodes}
                  onChange={val => this.onValueChange(false, index, "ErrorCodes", val)}
                  delimiter={/,|\n|\r\n/}
                  placeholder="Enter Error Codes"
                  avoidFocus="true"
                />
              </td>
              <td><i className="icon icon-trash" onClick={this.removeRow.bind(this, index)}></i></td>
            </tr>
          );
        });
    return {
      tHeader: <tr>
      <th>Appliance Type</th>
      <th>Ignored Error Codes</th>
      <th>Action</th>
    </tr>,
      rowData
    };
  },
  renderWarnings() {
    const {data} = this.state,
        rowData = data.map((item, index) => {
          const {
            edit: {
            Tooltip: editTooltip,
            JsonPath: editJsonPath,
            ThresholdValue: editThresholdValue
          },
          data: {
            Tooltip, JsonPath, ApplicableDeviceTypes, ThresholdValue, ConditionOperator, Severity}
          } = item;
          return (
            <tr key={`item${Tooltip}${index}`}>
              <td key={`in${Tooltip}1`}>
                <DataInput value={Tooltip}
                  editable={editTooltip}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "Tooltip", val)}
                />
              </td>
              <td key={`in${JsonPath}2`}>
                <DataInput value={JsonPath}
                  editable={editJsonPath}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "JsonPath", val)}
                />
              </td>
              <td key={`in${ApplicableDeviceTypes}3`} title="Add whole Device Types or Use Regular Expression e.g. 1.2.3.4 or 1.2.*">
              <MultiValueInput values={ApplicableDeviceTypes}
                  onChange={val => this.onValueChange(false, index, "ApplicableDeviceTypes", val)}
                  delimiter={/,|\n|\r\n/}
                  placeholder="Enter Device Types"
                  avoidFocus="true"
                />
              </td>
              <td key={`in${ThresholdValue}4`}>
                <DataInput value={ThresholdValue}
                  editable={editThresholdValue}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "ThresholdValue", val)}
                />
              </td>
              <td key={`in${ConditionOperator}5`}>
                <DropDown items={Service.ComparisonOperator}
                  defaultSelected={ConditionOperator}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "ConditionOperator", val)}
              />
              </td>
              <td key={`in${Severity}6`}>
                <DropDown items={Service.Severity} className="bb-alert"
                  defaultSelected={Severity}
                  onUpdate={(val, edit) => this.onValueChange(edit, index, "Severity", val)}
                />
              </td>
              <td><i className="icon icon-trash" onClick={this.removeRow.bind(this, index)}></i></td>
            </tr>
          );
        });
    return {
      tHeader: <tr>
      <th>Tooltip</th>
      <th>Evaluation Parameter JSON Path in Device Twin</th>
      <th>Applicable Appliance Type</th>
      <th>Comparison Threshold Value</th>
      <th>Comparison Operator</th>
      <th>Severity</th>
      <th>Action</th>
    </tr>,
      rowData
    };
  },
  isValidinputs() {
    const {data} = this.state;
    return _.some(data, (v, k) => {
      return _.some(v["data"], (val, key) => {
        return !val;
      });
    });
  },
  render() {
    const {type} = this.props,
        {tHeader, rowData} = this[`render${type}`](),
        className = `cat-column-table ${type}`;
    return (
      <div className={className}>
        <table className="table table-bordered">
          <thead key="thead">
            {tHeader}
          </thead >
          <tbody key="tbody">
            {rowData}
            <tr>
              <td colSpan="7"><button disabled={this.isValidinputs()} onClick={this.addRow} className="inline primary">Add New {configDataType[type]}</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
});

const DataInput = ReactClass({
  displayName: "DataInput",
  getInitialState: function () {
    return {
      editable: this.props.editable,
      value: this.props.value
    };
  },
/*   componentDidMount() {
    if(this.state.editable && this.input) {
      this.input.focus();
    }
  }, */
  handleClick() {
    this.setState({
      editable: !this.state.editable
    }, _=> {
      if (this.input) {
        this.input.focus();
      }
    });
  },
  handleInputChange(e) {
    this.setState({
      value: e.target.value
    }, () => this.valueChange());
  },
  handleInputClick(e) {
    e.preventDefault();
    e.stopPropagation();
  },
  handleBlur(e) {
    this.setState({
      editable: !this.state.editable,
      value: this.state.value
    }, () => this.valueChange());
  },
  handleKeyAction(e) {
    const {keyCode} = e,
        val = this.input && this.input.value;
    if (keyCode === 13 || keyCode === 9 || keyCode === 27) { // Enter, Tab key or ESC
      this.setState({
        editable: false,
        value: val
      }, () => this.valueChange());
    }
  },
  valueChange() {
    if ((this.props.onUpdate &&
      typeof (this.props.onUpdate) === "function")) {
      this.props.onUpdate(this.state.value, this.state.editable);
    }
  },
  render() {
    const {value, editable} = this.state;
    return (
      <div className="data-input" onClick={this.handleClick}>
        {(editable) ?
          <input
            ref={input => {
              this.input = input;
            }}
            type="text"
            value={value}
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            onClick={this.handleInputClick}
            onKeyUp={this.handleKeyAction}
            autoFocus={editable}
          />
          : <span><i className="icon icon-edit"></i> {value || "--"}</span>
        }
      </div>
    );
  }
});

const DropDown = ReactClass({
  displayName: "DropDown",
  getInitialState: function () {
    return {
      selectedItem: this.props.defaultSelected
    };
  },
  handleOnClick(item, e) {
    this.setState({
      selectedItem: item
    }, _=>{
      if ((this.props.onUpdate &&
        typeof (this.props.onUpdate) === "function")) {
        this.props.onUpdate(this.state.selectedItem, false);
      }
    });
  },
  renderItems() {
    const {items, className} = this.props, {selectedItem} = this.state;
    return items.map(item => {
      const clsName = `item ${className ? "bg-alert-"+item : ""} ${(item === selectedItem ? " selected" : "")}`;
      return (
        <li key={item}
          className={clsName}
          onClick={this.handleOnClick.bind(this, item)}>
          <span>{item}</span>
        </li>
      );
    });
  },
  render() {
    const {selectedItem} = this.state, {className} = this.props,
        clsName = `dropdown-header ${className ? className+"-"+selectedItem : ""}`;
    return (
      <div className="dropdown">
        <div className={clsName}>
          <span>{selectedItem || "Select Value"}</span>
        </div>
        <div className="dropdown-menu">
          <ul>
            {this.renderItems()}
          </ul>
        </div>
      </div>
    );
  }
});

export default ReactClass({
  displayName: "CatConfigView",
  getInitialState: function () {
    return {
      loading: true,
      catData: {},
      columns: [],
      errorExceptions: [],
      warnings: []
    };
  },
  fetchCatConfig() {
    Service.getConfig("CATProjects", "UiDisplayConfig").then(res => {
      const {ConfigData: {Columns = [], ErrorExceptions = [], Warnings = []} = {}} = res,
          columns = Service.getEditableFormData(!_.isEmpty(Columns) ? Columns : Service.Columns),
          errorExceptions = Service.getEditableFormData(!_.isEmpty(ErrorExceptions) ? ErrorExceptions : Service.ColuErrorExceptionsmns),
          warnings = Service.getEditableFormData(!_.isEmpty(Warnings) ? Warnings : Service.Warnings);
      this.setState({
        catData: res,
        loading: false,
        columns,
        errorExceptions,
        warnings
      });
    }).catch(errRes => {
      this.setState({
        catData: {},
        loading: false,
        columns: [],
        errorExceptions: [],
        warnings: []
      });
    });
  },
  componentDidMount() {
    this.fetchCatConfig();
  },
  onUpdate(field, val) {
    this.setState({
      [field]: val
    });
  },
  updateConfigData() {
    const {application: app} = this.props,
        {notifications} = app,
        {catData, columns, errorExceptions, warnings} = this.state,
        Columns = Service.convertForUpload(columns),
        ErrorExceptions = Service.convertForUpload(errorExceptions),
        Warnings = Service.convertForUpload(warnings),
        updatedData = Object.assign(catData, {ConfigData: {Columns, ErrorExceptions, Warnings}});
    
    app.showLoading(true);
    
    const file = new File([JSON.stringify(updatedData)], "CATProjects_UiDisplayConfig.json", {
      type: "application/json",
      endings: "native"
    });
    Service.uploadFile(file).then(resp => {
      app.showLoading(false);
      notifications.success(resp.message);
    }).catch(errJson => {
      app.showLoading(false);
      notifications.error("Failed to upload this configuration file.");
    });
  },
  discardConfigData() {
    const {ConfigData: {Columns = [], ErrorExceptions = [], Warnings = []} = {}} = this.state.catData,
        columns = Service.getEditableFormData(!_.isEmpty(Columns) ? Columns : Service.Columns),
        errorExceptions = Service.getEditableFormData(!_.isEmpty(ErrorExceptions) ? ErrorExceptions : Service.ColuErrorExceptionsmns),
        warnings = Service.getEditableFormData(!_.isEmpty(Warnings) ? Warnings : Service.Warnings);
    this.setState({
      columns,
      errorExceptions,
      warnings
    });
  },
  render() {
    const {loading, columns, errorExceptions, warnings} = this.state;
    return (
      <div className="view cat-project-config-view">
        <Navbar {...this.props}/>
        <div className="main-content">
          {loading ?
            <div className="loading">
              <div className="_text-center anim">
                <i className="icon icon-loader spin"></i>
              </div>
              <p className="_text-center">Loading Configuration...</p>
            </div>
            : <div className="section top-section">
              <div className="section-title">
                <div className="section-control">
                  <button className="inline primary" onClick={this.updateConfigData}>Update</button>
                  <button className="inline primary" onClick={this.discardConfigData}>Discard</button>
                </div>
              </div>
              <div>
                <div className="section">
                  <h2 className="section-title">Custom columns</h2>
                  <CustomColumns type="Columns"
                    data={columns}
                    onUpdate={val => this.onUpdate("columns", val)} key="Columns"/>
                </div>
                <div className="section">
                  <h2 className="section-title error-section">Ignore Errors</h2>
                  <CustomColumns type="ErrorExceptions"
                    data={errorExceptions}
                    onUpdate={val => this.onUpdate("errorExceptions", val)} key="ErrorExceptions"/>
                </div>
                <div className="section">
                  <h2 className="section-title">Warnings</h2>
                  <CustomColumns type="Warnings"
                    data={warnings}
                    onUpdate={val => this.onUpdate("warnings", val)} key="Warnings"/>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
});

